import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './China.less';
import b from '@img/learn/chinaBanner.png';
import china1 from '@img/learn/china1.png';
import {Helmet} from "react-helmet";
import {get, post} from '@/server/http';
import {pxAndRemInterturn} from '@/server/util';
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const China = ({ lang }) => {
    let seo_data = localStorage.getItem('seo_data');
    const [detail, setDetail] = useState({});
    useEffect(() => {
      const trackPage = page => {
        ReactGA.set({
          page,
        });
        ReactGA.pageview(page);
      };
      trackPage(window.location.href)

     let _detail= {};
     const getDetail = get('/api/page/china').then(res => {
         _detail = res.data
         // console.log(_detail)
         var china_content = _detail.china_content
         var china_en_content = _detail.china_en_content
         var regex = new RegExp('<img', 'gi');
         _detail.china_content = china_content.replace(regex, '<img style="width: 100%;"');
         _detail.china_en_content = china_en_content.replace(regex, '<img style="width: 100%;"');
         //px转rem
         _detail.china_content = pxAndRemInterturn(_detail.china_content,'px','rem')
         _detail.china_en_content = pxAndRemInterturn(_detail.china_en_content,'px','rem')
         if(seo_data){
           seo_data = JSON.parse(seo_data)
           if(!_detail.seo_title){
             _detail.seo_title = seo_data.seo_title
           }
           if(!_detail.seo_keywords){
             _detail.seo_keywords = seo_data.seo_keywords
           }
           if(!_detail.seo_description){
             _detail.seo_description = seo_data.seo_description
           }
           if(!_detail.seo_en_title){
             _detail.seo_en_title = seo_data.seo_en_title
           }
           if(!_detail.seo_en_keywords){
             _detail.seo_en_keywords = seo_data.seo_en_keywords
           }
           if(!_detail.seo_en_description){
             _detail.seo_en_description = seo_data.seo_en_description
           }
         }
         setDetail(_detail)
     })
    }, []);

    return (
        <div className='mainContBox'>
            <Helmet>
                <title>{lang?detail.seo_title:detail.seo_en_title}</title>
                <meta name="description" content={lang?detail.seo_description:detail.seo_en_description} />
                <meta name="keywords" content={lang?detail.seo_keywords:detail.seo_en_keywords} />
            </Helmet>
            <BannerItem src={lang?detail.china_banner_image:detail.china_en_banner_image} />
            <OverPack className='childContent' playScale={0.2} always={false}>
                <QueueAnim
                    type="bottom"
                    key="ul"
                    leaveReverse
                >
                    {
                        lang ? <Navtip title={['学术课堂']} subtitle='中国语言文化教育' /> : <Navtip title={['Academic classroom']} subtitle='Chinese language and culture education' />
                    }

                    <div className='textBox'>
                         <div dangerouslySetInnerHTML={{__html: lang?detail.china_content:detail.china_en_content}}></div>
                    </div>
                </QueueAnim>
            </OverPack>
        </div>
    )
};

export default connect(
    state => ({
        lang: state['lang'],
    })
)(China);