import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './TeamDetail.less';
import b from '@img/team/teamDetialBanner.png';
import b_en from '@img/team/teamDetialBanner_en.png';
import { Link } from "react-router-dom";

import Dino from '@img/team/Dino.png';
import James from '@img/team/James.png';
import Mira from '@img/team/Mira.png';
import {Helmet} from "react-helmet";
import {get, post} from '@/server/http';
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const team1 = [
  { img: Dino, name: 'Gisiano', p: '校 长', p_en: 'Principal' },
  { img: James, name: 'James', p: '市场招生总监', p_en: 'Marketing and Admission Director' },
  { img: Mira, name: 'Mira', p: '校长助理', p_en: 'Assistant Principal' },
];
const team3 = [
  { img: 'http://cqbs.hicools.cn/teams/Alice.png', name: 'Alice', p: '市场官员', p_en: 'Marketing Officer' },
  { img: 'http://cqbs.hicools.cn/teams/Allen.png', name: 'Allen', p: '市场助理', p_en: 'Marketing Assistant' },
  { img: 'http://cqbs.hicools.cn/teams/Cheryl.png', name: 'Cheryl', p: '行政助理', p_en: 'Administrative Assistant' },
  { img: 'http://cqbs.hicools.cn/teams/Cindy.png', name: 'Cindy', p: '人事经理', p_en: 'HR Manager' },
  { img: 'http://cqbs.hicools.cn/teams/Elena.png', name: 'Elena', p: '校 医', p_en: 'Nurse' },
  { img: 'http://cqbs.hicools.cn/teams/Linda.png', name: 'Linda', p: '财务官员', p_en: 'Finance Officer' },
  { img: 'http://cqbs.hicools.cn/teams/Luis.png', name: 'Luis', p: '后勤经理', p_en: 'Logistic Manager' },
  { img: 'http://cqbs.hicools.cn/teams/Mandy.png', name: 'Mandy', p: '高级招生官员', p_en: 'Senior Admission Officer' },
  { img: 'http://cqbs.hicools.cn/teams/Stanley.png', name: 'Stanley', p: '技术官员', p_en: 'IT Officer' },
  { img: 'http://cqbs.hicools.cn/teams/Tina.png', name: 'Tina', p: '财务经理', p_en: 'Finance Manager' },
];
const team2 = [
  { img: 'http://cqbs.hicools.cn/teams/ELSA.png', name: 'Elsa Victoria', p: '幼儿园老师',  p_en: 'PreK Teacher' },
  { img: 'http://cqbs.hicools.cn/teams/ROSIE.png', name: 'Rosie Olivares', p: '学前班老师', p_en: 'Kindergarten' },
  { img: 'http://cqbs.hicools.cn/teams/Susan.png', name: 'Susan Carrara', p: '一年级老师', p_en: 'G1 Teacher' },
  { img: 'http://cqbs.hicools.cn/teams/DANIEL.png', name: 'Daniel Soedirga', p: '二年级老师', p_en: 'G2 Teacher' },
  { img: 'http://cqbs.hicools.cn/teams/TARA.png', name: 'Tara Powers', p: '三-五年级英语 科学老师', p_en: 'G3-5 ELA & Science Teacher' },
  { img: 'http://cqbs.hicools.cn/teams/JaJared.png', name: 'Jared Simpson', p: '三-五年级英语 社会学老师', p_en: '3-5 grade sociology teacher of English' },
  { img: 'http://cqbs.hicools.cn/teams/DANIELDENG.png', name: 'Daniel Deng', p: '三-五年级数学老师', p_en: 'G3-5 Math Teacher' },
  { img: 'http://cqbs.hicools.cn/teams/SHERRYLI.png', name: 'Sherry Li', p: '托 班', p_en: 'Toddler' },
  { img: 'http://cqbs.hicools.cn/teams/BettyChenms.png', name: 'Betty Chenms', p: '初中科学 数学老师', p_en: 'High School Math Teacher' },
  { img: 'http://cqbs.hicools.cn/teams/DonaldXIao.png', name: 'Donald Xiao', p: '高中数学老师', p_en: 'High school math teacher' },
  { img: 'http://cqbs.hicools.cn/teams/JUSTIN.png', name: 'Justin Rainey', p: '社学会老师', p_en: 'Social Studies Teacher' },
  { img: 'http://cqbs.hicools.cn/teams/RyanMarie.png', name: 'Ryan Marie Wardlaw-Abdullah', p: '英语老师', p_en: 'ELA Teacher' },
  { img: 'http://cqbs.hicools.cn/teams/Joan.png', name: 'Joan Wang', p: '科 学', p_en: 'Science' },
  { img: 'http://cqbs.hicools.cn/teams/JESSIE.png', name: 'Jessie Tu', p: '中文老师', p_en: 'Chinese Language' },
  { img: 'http://cqbs.hicools.cn/teams/MARYWANG.png', name: 'Mary Wang', p: '中文老师', p_en: 'Chinese Language' },
  { img: 'http://cqbs.hicools.cn/teams/ZoeZeng.png', name: 'Zoe Zeng', p: '中文老师', p_en: 'Chinese Language' },
  { img: 'http://cqbs.hicools.cn/teams/TINAYI.png', name: 'Tina Yi', p: '语言老师', p_en: 'ESL Teacher' },
  { img: 'http://cqbs.hicools.cn/teams/Melina.png', name: 'Melina Xu', p: '音乐老师', p_en: 'Music' },
  { img: 'http://cqbs.hicools.cn/teams/LORIS.png', name: 'Loris Zhang', p: '美术老师', p_en: 'Art Teacher' },
  { img: 'http://cqbs.hicools.cn/teams/WinnieYang.png', name: 'Winnie Yang', p: '体育老师', p_en: 'PE Teacher' },
  { img: 'http://cqbs.hicools.cn/teams/LynnLi.png', name: 'Lynn Li', p: '一年级助教', p_en: 'G1 TA Teacher' },
  { img: 'http://cqbs.hicools.cn/teams/Fionasun.png', name: 'Fiona Sun', p: '二年级助教', p_en: 'G2 TA' },
  { img: 'http://cqbs.hicools.cn/teams/JennyLiu.png', name: 'Jenny Liu', p: '3-5年级助教', p_en: 'G3-5 TA' },
  { img: 'http://cqbs.hicools.cn/teams/SallyZhao.png', name: 'Sally Zhao', p: '3-5年级助教', p_en: 'G3-5 TA' },
  { img: 'http://cqbs.hicools.cn/teams/JannaCao.png', name: 'Janna Cao', p: '学前班助教', p_en: 'Kindergarten TA' },
  { img: 'http://cqbs.hicools.cn/teams/StellaShu.png', name: 'Stella Shu', p: '幼儿园助教' , p_en: 'PreK TA'},
  { img: 'http://cqbs.hicools.cn/teams/CocoYin.png', name: 'Coco Yin', p: '幼儿园保育员' , p_en: 'PreK Nanny'},
  { img: 'http://cqbs.hicools.cn/teams/QingWang.png', name: 'Qingli Wang', p: '托班保育员', p_en: 'Toddler Nanny' },
  { img: 'http://cqbs.hicools.cn/teams/Sharonzhao.png', name: 'Sharon Zhao', p: '托班助教', p_en: 'Toddler TA' },
];
const TeamDetail = ({lang}) => {
  let seo_data = localStorage.getItem('seo_data');
  seo_data = JSON.parse(seo_data)
  const { type } = useParams();
  const [subtitle, setSubtitle] = useState('');
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const trackPage = page => {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    };
    trackPage(window.location.href)
    
    let _subtitle = '', _teams= [];
    switch (type) {
      case '10':
        _subtitle = lang?'管理团队':'Leadership Team';
        break;
      case '20':
				_subtitle = lang?'学术团队':'Academic Team';
        break;
      default:
				_subtitle = lang?'支持团队':'Supporting Team';
    }
    setSubtitle(_subtitle);
    const getList = () => {
      get('/api/team/lists',{type:type}).then((res) => {
        _teams = res.data.list
        console.log(res)
        setTeams(_teams)
      })
    }
    getList();
  }, [type]);

  return (
    <div className='teamDetailContent mainContBox'>
      <Helmet>
          <title>{lang?seo_data.seo_title:seo_data.seo_en_title}</title>
          <meta name="description" content={lang?seo_data.seo_description:seo_data.seo_en_description} />
          <meta name="keywords" content={lang?seo_data.seo_keywords:seo_data.seo_en_keywords} />
      </Helmet>
      <BannerItem src={lang?b:b_en} />
      <div className='conentBox'>
        <OverPack className='childContent' playScale={0.2}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
          >
            <div className='navContent'>
              <Link className='navA' to={'/'}>{lang ? '首页' : 'Index'}</Link> {`>`}
              <span> {lang ? '关于我们': 'About us'}</span> {`>`}
              <Link className='navA' to={'/team'}> { lang ? '学校团队' : 'The school team'}</Link> {`>`}
              <span className='tip'> {subtitle}</span>
            </div>
            <h1 className='teamTitle' key='h1'>{subtitle}</h1>
            <div className='teamBox' key='box'>
              {
                teams.map((t, i) => (
                  <div key={i} className='peopleItem'>
                    <div className='imgBox' style={{ background: `url(${t.image? t.image.file_path: ''}) no-repeat center #D7D7D7`, backgroundSize: 'contain' }}></div>
                    <div className='name'>{lang ? t.name: t.en_name}</div>
                    <div className='zhi'>{lang ? t.position: t.en_position}</div>
                  </div>
                ))
              }
            </div>
          </QueueAnim>
        </OverPack>
      </div>
    </div>
  )
};

export default connect(
    state => ({
        lang: state['lang'],
    })
)(TeamDetail);