import React from 'react';
import { connect } from 'react-redux';
import './Navtip.less';
import { Link } from "react-router-dom";


const Navtip = (props) => {
  const { title = [], subtitle = '', url = '',lang } = props;
  return(
    <div className='navContent'>
      <Link className='navA' to={'/'}>{lang ? '首页' : 'Home'}</Link> {`>`} {
        title.length > 0 ? title.map(t => (
          <span key={t}> {t} {`>`} </span>
        )) : ''
      } <Link to={url}><span className='tip'>{subtitle}</span></Link>
    </div>
  )
};


export default connect (
  state => ({
    lang: state['lang'],
  })
)(Navtip);