import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './JoinUs.less';
import b from '@img/about/joinBanner.png';
import b_en from '@img/about/joinBannerEn.png';
import life from '@img/about/life.png';
import life2 from '@img/about/life2.png';
import life3 from '@img/about/life3.png';
import fee from '@img/about/fee.png';
import {Helmet} from "react-helmet";
import {get, post} from '@/server/http';
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const JoinUs = ({ lang }) => {
  let seo_data = localStorage.getItem('seo_data');
  const [list, setlist] = useState([]);
  const [detail, setDetail] = useState({});
  useEffect(() => {
    const trackPage = page => {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    };
    trackPage(window.location.href)
    let _list= [];
    const getList = get('/api/position/lists').then(res => {
        _list = res.data.list
        // console.log(_list)
        setlist(_list)
    })
    let _detail= {};
    const getDetail = get('/api/page/join').then(res => {
        _detail = res.data
        // console.log(_detail)
        if(seo_data){
          seo_data = JSON.parse(seo_data)
          if(!_detail.seo_title){
            _detail.seo_title = seo_data.seo_title
          }
          if(!_detail.seo_keywords){
            _detail.seo_keywords = seo_data.seo_keywords
          }
          if(!_detail.seo_description){
            _detail.seo_description = seo_data.seo_description
          }
          if(!_detail.seo_en_title){
            _detail.seo_en_title = seo_data.seo_en_title
          }
          if(!_detail.seo_en_keywords){
            _detail.seo_en_keywords = seo_data.seo_en_keywords
          }
          if(!_detail.seo_en_description){
            _detail.seo_en_description = seo_data.seo_en_description
          }
        }
        setDetail(_detail)
    })
  }, []);

  return (
    <div className='mainContBox joinContent'>
      <Helmet>
          <title>{lang?detail.seo_title:detail.seo_en_title}</title>
          <meta name="description" content={lang?detail.seo_description:detail.seo_en_description} />
          <meta name="keywords" content={lang?detail.seo_keywords:detail.seo_en_keywords} />
      </Helmet>
      <BannerItem src={lang?b:b_en} />
      <OverPack className='childContent' playScale={0.2}>
        <QueueAnim
          type="bottom"
          key="ul"
          leaveReverse
        >
				
        {
        lang ? (
         <>
          <Navtip title={['关于我们']} subtitle='职业发展' />
          <div className='textBox'>
          
            <div className='itemBox'>
              <div className='pageTitle'>在学校工作</div><br/>
              重庆诺林巴蜀外籍人员子女学校（简称诺林巴蜀或KLIS）有一个目标——提供从幼儿园到12年级真正的世界级美国教育。学校使用美国Common Core课程标准，是重庆市中心首家获得美国Cognia认证的国际学校，学校培养的毕业生满足全球顶尖名校的录取要求。学校满足居住在成渝地区的国际外籍家庭的高质量教育标准和需求。美国的教育体系被国际公认为英语世界中最好的课程体系之一。学校可容纳400名从幼儿园到12年级的学生。KLIS为教师提供最新的课程资源和技术学习工具。所有的西方教师与双语的中国ESL教师和助教一起工作，以支持学生的成功。KLIS相信个性化的方法可以帮助学生取得成功。因此，我们的班级很小。我们的班级人数上限是小学部17人，中学部20人。<br/><br/>
              KLIS教师是充满激情、专注和关怀的专业人士，他们培养和引导每一个学生探索他们的才能，实现他们的梦想。我们的教师100%有教学和评估实践经验。KLIS拥有来自美国和世界各地平均10年教育经验的高素质教师。<br/>
              </div>
              {/*<div className='itemBox'>
              <br/>
              <div className='pageTitle'>职位空缺:</div><br/>
              
              {
                list.map((t,i) => (
                  <a className='link' target='_blank' href={t.file? t.file.file_path: ''}  download  key={i}>{t.title}</a>
                ))
              }
              
              </div>*/}
              <div className='itemBox'>
              <br/>
              <div className='pageTitle'>如何申请:</div><br/>
            有意者请将简历发送至<span className='emailText'>{detail.email}</span>。我们感谢所有对学校职位感兴趣的申请者，但是，合适具体岗位的申请者才会被HR联系。
            </div>
            <br/>
            <br/>
            <div className='pageTitle'>生活在重庆</div>
            <p className='itemBox'>重庆位于中国西南部长江和嘉陵江的汇合处。它东邻湖北和湖南，南接贵州，西、北接四川，东北角是陕西。它是1997年成立的四大直辖市之一，也是中国西部唯一的直辖市。重庆直接向中央政府报告。由21个区、17个县组成，已成为中国西部地区最大的经济中心、重要的交通枢纽和内陆港口。</p>
            <p className='itemBox'>重庆是中国发展最快的城市之一。除了三峡大坝工程的建设，它的快速经济增长也可以归因于中国的“优惠政策”，特别是2000年启动的“西部大开发”战略，旨在为中国西部地区带来投资。重庆在很大程度上受益于这项政策，成为了一个重要的贸易和制造业中心，以及通往中国西部的主要门户。由于地理位置优越，重庆也是“一带一路”倡议的“关键战略节点”。“一带一路”倡议于2013年提出，旨在通过中国连接三大洲65个国家。</p>
            <p className='itemBox'>重庆人口众多，是中国西南地区重要的消费市场。一些主要的本地和海外零售商已经在重庆建立了他们的业务，包括法国的家乐福，美国的沃尔玛，德国的麦德龙和瑞典的宜家。</p>
            <p className='itemBox'>重庆拥有丰富的自然和文化遗产。除了令人叹为观止的山川、河流、森林、泉水、瀑布峡谷和洞穴，重庆还有大足石刻、三峡、小三峡和奉节的天然坑和地裂缝。重庆被称为“山城”，因为它被群山环绕。很明显，你拥有你所居住的城市的所有便利设施，但你周围有大自然。重庆拥有许多历史名胜和旅游景点。其中包括大韩民国临时政府遗址、合川的渔民城堡、古代战场和丰都的鬼城。</p>
            <p className='itemBox'>有很多外籍人士住在重庆，而且这个数字每年都在增长，结交朋友和拥有良好的社交生活应该不成问题。重庆可能是一个大多数人都没听说过的城市，但它确实提供了你在这个你称之为家的城市想要的所有娱乐。购物中心、酒吧、俱乐部、电影院、保龄球馆、徒步旅行等等应有尽有。</p>
			<div className='textImgBox itemBox' style={{ background: `url(${life}) no-repeat center #D7D7D7`, backgroundSize: 'cover' }}></div>
			<div className='textImgBox itemBox' style={{ background: `url(${life2}) no-repeat center #D7D7D7`, backgroundSize: 'cover' }}></div>
			<div className='textImgBox itemBox' style={{ background: `url(${life3}) no-repeat center #D7D7D7`, backgroundSize: 'cover' }}></div>
					  <br/>
						<div className='pageTitle'>平均生活费</div>
						<p className='itemBox flex'>
							<span className='itemTitle'>房租(每月)</span>
							<span className='itemText'>单间/1间卧室:1800 - 2200人民币(260-317美元)
							2居室- 2200 - 3000人民币(317-433美元)</span>
						</p>
						<p className='itemBox flex'>
							<span className='itemTitle'>生活费(每月)</span>
							<span className='itemText'>电、水、气——100-200人民币(16-31美元)</span>
						</p>
						<p className='itemBox flex'>
						<span className='itemTitle'>电话费(每月)</span>
							<span className='itemText'>每月99人民币(14美元)，无限流量(包括家中wifi)</span>
						</p>
						<p className='itemBox flex'>
							<span className='itemTitle'>食品/饮料(每餐)</span>
							<span className='itemText'>中餐:10-20人民币(1.50-2.80美元)
							西餐(汉堡、香肠、披萨等)- 30-80人民币(4.30-11美元)
							国产啤酒:5人民币(0.70美元)</span>
						</p>
						<p className='itemBox flex'>
							<span className='itemTitle'>交通/休闲</span>
							<span className='itemText'>单程票(视距离而定):人民币1-6人民币(0.15-0.87美元)
							1个月的健身房会员-人民币100-200人民币(15-30美元)
							电影票:40人民币(5美元)</span>
						</p>
						<div className='textImgBox itemBox' style={{ background: `url(${fee}) no-repeat center #D7D7D7`, backgroundSize: 'cover' }}></div>
						
          </div>

          </>
          ):(
          <>
          
          <Navtip title={['About KLIS']} subtitle='Careers' />
          <div className='textBox'>
            <div className='itemBox'>
            <div className='pageTitle'>Working at KLIS</div><br/>
            KL International School of Chongqing (KLIS) has one goal - to provide an authentic world-class American education from Prekindergarten to Grade 12. KLIS follows the Common Core curriculum standards and was the first Cognia accredited international school in Chongqing to graduate students qualified for top universities around the world. KLIS meets the high-quality standards and needs of international expatriate families living in the Chengyu area. American education system is recognized internationally as one of the best learning systems in the English-speaking world. KLIS campus has capacity for 300 students from Prekindergarte to Grade 12. KLIS provides teachers with the most up to date curriculum resources and technology learning tools.  All Western teachers work alongside bi-lingual Chinese ESL teachers and TAs to support student success.  KLIS believes in a personalized approach to student success.  As a result, our classes are small.  We have class size caps of 17 in Primary and 20 in Secondary.<br/><br/>
            KLIS teachers are passionate, committed and caring professionals, who nurture and guide each student in exploring their talents and fulfilling their dreams. 100% of our teachers have proven teaching and assessment practices. KLIS employs highly qualified teachers with degrees in Education, with an average of 10 years of experience from America and internationally.<br/>
            </div>

            <div className='itemBox'>
            <br/>
            <div className='pageTitle'>How to Apply</div><br/>
            Please send your CV to <span className='emailText'>{detail.email}</span>. We thank all applicants for your interest in a position at KLIS, however, only those selected for an interview will be contacted.
            </div>
            <br/>
            <br/>
            <div className='pageTitle'>Living in Chongqing</div>
            <p className='itemBox'>Chongqing is located at the confluence of the Yangtze and Jialing Rivers in southwest China. It is surrounded by Hubei and Hunan to the east, Guizhou to the south, Sichuan to the west and north, and Shaanxi at its northeast corner. It was established as one of the four direct-controlled municipalities in 1997 and the only one in west China. Chongqing reports directly to the central government. Comprising 21 districts and 17 counties, it has become the largest economic centre in west China as well as an important transportation hub and an inland port.</p>
            <p className='itemBox'>Chongqing is one of the fastest growing cities in China. Besides the construction of the Three Gorges Dam Project, its rapid economic growth could also be attributed to China’s “favourable policies”, particularly the “Go West” strategy launched in 2000, which aims to generate investment in western China. Chongqing has largely benefitted from the policy and became a significant trading and manufacturing hub as well as a major gateway to west China. With its strategic location, Chongqing is also “a key strategic node” of the Belt and Road Initiative, which was unveiled in 2013 and aims to connect 65 countries across three continents through China.</p>
            <p className='itemBox'>Chongqing is an important consumer market in southwest China due to its large population. Some of the major local and overseas retailers that have established their presence in Chongqing include Carrefour from France, Walmart from the United States, Metro from Germany and IKEA from Sweden.</p>
            <p className='itemBox'>Chongqing enjoys a great natural and cultural heritage. Besides its breathtaking landscapes of mountains, rivers, forests, springs, waterfall gorges and caves, Chongqing also has the Dazu Rock Carvings, the Three Gorges, Small Little Gorges and the Natural Pit and Land Crack in Fengjie. Chongqing is nicknamed the "Mountain City" because it's surrounded by mountains. So you obviously have all the amenities of the city where you live, but you have nature all around you. Chongqing boasts a number of historic sites and tourist attractions. These include the site of the provisional government of the Republic of Korea, and the Fishermen’s Castle in Hechuan, an ancient battlefield and the Capital of the Ghost World in Fengdu.</p>
            <p className='itemBox'>There are lots of expats living in Chongqing, and the number continues to grow every year, you shouldn't have a problem making friends and having a great social life. Chongqing may be a city that most people haven't heard of, but it really does offer all the entertainment you could want in the city you call home. There is a plethora of malls, bars, clubs, cinemas, bowling alleys, hikes, and so much more.</p>
<div className='textImgBox itemBox' style={{ background: `url(${life}) no-repeat center #D7D7D7`, backgroundSize: 'cover' }}></div>
			<div className='textImgBox itemBox' style={{ background: `url(${life2}) no-repeat center #D7D7D7`, backgroundSize: 'cover' }}></div>
			<div className='textImgBox itemBox' style={{ background: `url(${life3}) no-repeat center #D7D7D7`, backgroundSize: 'cover' }}></div>
					  <br/>
						<div className='pageTitle'>Cost of Living Averages</div>
						<p className='itemBox flex-column'>
							<span className='itemTitle'>Rent (per month)</span>
							<span className='itemText'>Studio/1 bedroom- RMB 1,800-2,200 ($260-317 USD)
	2 bedroom- RMB 2,200-3,000 ($317-433 USD)</span>
						</p>
						<p className='itemBox flex-column'>
							<span className='itemTitle'>Utilities (per month)</span>
							<span className='itemText'>Electricity, water, gas- RMB 100-200 ($16-31 USD)</span>
						</p>
						<p className='itemBox flex-column'>
						<span className='itemTitle'>Phone bill (per month)</span>
							<span className='itemText'>RMB 99 ($14 USD) per month for unlimited data 
	(includes wifi for your home)</span>
						</p>
						<p className='itemBox flex-column'>
							<span className='itemTitle'>Food/Drink (per meal)</span>
							<span className='itemText'>Chinese meal- RMB 10-20 ($1.50-2.80 USD)
	Basic Western meal (burger, pizza, etc.)- RMB 30-80 ($4.30-11 USD)
	Domestic Beer- RMB 5 ($0.70 USD)</span>
						</p>
						<p className='itemBox flex-column'>
							<span className='itemTitle'>Transportation/Leisure</span>
							<span className='itemText'>One-way ticket (depending on distance)- RMB 1-6 ($0.15-0.87 USD)
	1 month gym membership- RMB 100-200 ($15-30 USD)
	Movie ticket- RMB 40 ($5 USD)</span>
						</p>
						<div className='textImgBox itemBox' style={{ background: `url(${fee}) no-repeat center #D7D7D7`, backgroundSize: 'cover' }}></div>
						
          </div>

          </>
					
          )
					
        }
        </QueueAnim>
      </OverPack>
    </div>
  )
};

export default connect (
  state => ({
    lang: state['lang'],
  })
)(JoinUs);