import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './Map.less';
import b from '@img/learn/mapBanner.png';
import mapImg from '@img/learn/mapImg.png';
import {Helmet} from "react-helmet";
import {get, post} from '@/server/http';
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const Map = ({ lang }) => {
    let seo_data = localStorage.getItem('seo_data');
    const [detail, setDetail] = useState({});
    useEffect(() => {
      const trackPage = page => {
        ReactGA.set({
          page,
        });
        ReactGA.pageview(page);
      };
      trackPage(window.location.href)
      
      let _detail= {};
      const getDetail = get('/api/page/map').then(res => {
          _detail = res.data
          if(seo_data){
            seo_data = JSON.parse(seo_data)
            if(!_detail.seo_title){
              _detail.seo_title = seo_data.seo_title
            }
            if(!_detail.seo_keywords){
              _detail.seo_keywords = seo_data.seo_keywords
            }
            if(!_detail.seo_description){
              _detail.seo_description = seo_data.seo_description
            }
            if(!_detail.seo_en_title){
              _detail.seo_en_title = seo_data.seo_en_title
            }
            if(!_detail.seo_en_keywords){
              _detail.seo_en_keywords = seo_data.seo_en_keywords
            }
            if(!_detail.seo_en_description){
              _detail.seo_en_description = seo_data.seo_en_description
            }
          }
          // console.log(_detail)
          setDetail(_detail)
      })
    }, []);

    return (
        <div className='mapContent mainContBox'>
            <Helmet>
                <title>{lang?detail.seo_title:detail.seo_en_title}</title>
                <meta name="description" content={lang?detail.seo_description:detail.seo_en_description} />
                <meta name="keywords" content={lang?detail.seo_keywords:detail.seo_en_keywords} />
            </Helmet>
            <BannerItem src={lang ? detail.map_banner_image:detail.map_en_banner_image} />
            <OverPack className='childContent' playScale={0.2}>
                <QueueAnim
                    type="bottom"
                    key="ul"
                    leaveReverse
                >
                    {
                        lang ? <Navtip title={['学术课堂']} subtitle='MAP测评' /> : <Navtip title={['Academic classroom']} subtitle='The MAP measurement' />
                    }
                    <div className='pageTitle'>{lang ? '学业进步的衡量标准，KLIS 的 MAP 测评' : 'Measures of Academic Progress, MAP Testing at KLIS'}</div>
                    <div className='textBox' style={{ lineHeight: '1.8' }}>
                        <div dangerouslySetInnerHTML={{__html: lang?detail.map_content:detail.map_en_content}}></div>
                    </div>
                </QueueAnim>
            </OverPack>
        </div>
    )
};

export default connect(
    state => ({
        lang: state['lang'],
    })
)(Map);