import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './Camp.less';
import b from '@img/learn/jj.jpg';
import eng from '@img/system/eng.png'
import j_ewm from '@img/learn/j_ewm.jpg';
import j_show2 from '@img/learn/j_show2.jpg';
import j_1 from '@img/learn/1.jpg';
import j_2 from '@img/learn/2.jpg';
import j_3 from '@img/learn/3.jpg';
import j_4 from '@img/learn/4.jpg';
import j_5 from '@img/learn/5.jpg';
import j_6 from '@img/learn/6.jpg';
const Eng = ({ lang }) => {
  useEffect(() => {

  }, []);

  return (
    <div className='engContent mainContBox'>
      <BannerItem src={b} />
      <OverPack className='childContent' playScale={0.2}>
        <QueueAnim
          type="bottom"
          key="eng"
          leaveReverse
        >
          {
            lang ? <Navtip title={['最新动态']} subtitle='2023 夏令营' /> : <Navtip title={['Academics']} subtitle='2023 Summer Course/Summer Camp' />
          }
          <img src={j_1} alt="" className='j_1' key='j_1' />
					<div className='engTitle' key='engText1'>
						{
						  lang ? 'THE DEEP STEAM & PBL夏令营'
							: 'THE DEEP STEAM & PBL Summer Camp'
						}
					</div>
					<div className='engTitle' key='engText1'>
						{
						  lang ? '一场科学发现之旅'
							: 'A journey of scientific discovery'
						}
					</div>
					<div className='engText' key='engText1'>
					  {
					    lang ? '项目制学习：将深度体验、趣味游戏、团队协作活动充分结合，使学生们的创新精神、科学素养、表达能力和解决实际问题四方面得到同步提高。'
					      : 'PBL: Full combination of in-depth experience, fun games, and teamwork activities will simultaneously improve students’ innovative spirit, scientific literacy, expression, and problem-solving ability. '
					  }
					</div>
					<div className='engTitle' key='engText1'>
						{
						  lang ? '专业外籍导师团队'
							: 'Professional foreign teacher team'
						}
					</div>
					<div className='engText' key='engText1'>
					  {
					    lang ? '由KLIS资深小学班主任老师和拥有丰富PBL教学经验的KLIS学术总监组成金牌导师团队将给予学生语言能力、数理思维、软技能等全方位支持。'
					      : 'The professional foreign teacher team consisted of a KLIS senior primary school head teacher and the KLIS academic director with rich PBL teaching experience will give students all-round support in language ability, mathematical thinking, soft skills, etc. '
					  }
					</div>
					<div className='engTitle' key='engText1'>
						{
						  lang ? '夏令营时间'
							: 'Time'
						}
					</div>
					<div className='engText' key='engText1'>
					  {
					    lang ? '2023年7月31日至8月11日两个星期，周一至周五，上午9点至下午4点'
					      : 'July 31, 2023 to August 11 (two weeks), Monday to Friday, 9am to 4pm '
					  }
					</div>
					
					<div className='engTitle' key='engText2'>
						{
						  lang ? 'ESL 夏令营'
							: 'ESL Summer Camp'
						}
					</div>
					<div className='engTitle' key='engText2'>
						{
						  lang ? '解码英文阅读的奥义'
							: 'Decoding the mysteries of English reading'
						}
					</div>
					<div className='engText' key='engText2'>
					  {
					    lang ? '专注于培养英文音素认知、自然拼读、阅读流畅性、词汇量及阅读理解能力！'
					      : 'ESL Summer Campers will focus on cultivating English phoneme recognition, natural spelling, reading fluency, vocabulary, and reading comprehension abilities!'
					  }
					</div>
					<div className='engTitle' key='engText2'>
						{
						  lang ? '个性化分级教学'
							: 'Scholarship Categories'
						}
					</div>
					<div className='engTitle' key='engText2'>
						{
						  lang ? '针对性的教学方式与练习，满足每一位学生个性化的学习需求！'
							: 'Scholarship Categories'
						}
					</div>
					<div className='engTitle' key='engText2'>
						{
						  lang ? '招生对象'
							: 'Scholarship Categories'
						}
					</div>
					<div className='engTitle' key='engText2'>
						{
						  lang ? '幼儿园大班至十二年级'
							: 'Scholarship Categories'
						}
					</div>
					<div className='engTitle' key='engText2'>
						{
						  lang ? '夏令营时间'
							: 'Scholarship Categories'
						}
					</div>
					<div className='engText' key='engText2'>
					  {
					    lang ? '2023年7月31日至8月11日，每周一至周五，每天90分钟'
					      : 'Platinum:  100% of Tuition;Gold:  50% of Tuition;Silver:  Up to 30% of Tuition'
					  }
					</div>
					<div className='engTitle' key='engText3'>
						{
						  lang ? '综合评定体系'
							: 'Overall Framework'
						}
					</div>
					<div className='engText' key='engText3'>
					  {
					    lang ? '学校将综合评估学生的申请和资料。评定小组将根据五个主要成就领域中的一个或多个，从定性和定量进行考虑和判断：'
					      : 'Your application and portfolio will be considered holistically. The panel will make its judgment based on qualitative and quantitative considerations under one or more of the five broad areas of achievement:  '
					  }
					</div>
					<div className='engText' key='engText3'>
					 {
					   lang ? '1.语言: 中文，英语 '
					 	: '1.Language Arts: Chinese, English '
					 }
					</div>
					<div className='engText' key='engText3'>
					  {
					    lang ? '2.数学 / 科学 / 人文学科 / 技术学科'
					      : '2. Mathematics / Sciences / Humanities / Technology '
					  }
					</div>
					<div className='engText' key='engText3'>
					  {
					    lang ? '3.艺术学科: 视觉艺术，音乐'
					      : '3.The Arts: Visual Art, Music;'
					  }
					</div>
					<div className='engText' key='engText3'>
					  {
					    lang ? '4.运动与行动: 体育'
					      : '4. 	Sports and Action: Physical Education ;'
					  }
					</div>
					<div className='engText' key='engText3'>
					  {
					    lang ? '5.社区服务与领导力'
					      : '5. 	Service and Leadership '
					  }
					</div>
					
					<img src={j_show2} alt="" className='j_show2' key='j_show2' />
					
					<div className='engTitle' key='engText4'>
						{
						  lang ? '新生需要提交评估的材料'
							: 'New Student Items Required for Submission and Consideration'
						}
					</div>
					<div className='engText' key='engText4'>
					  {
					    lang ? '1.已填写完整的诺林巴蜀奖学金申请表；'
					      : '1.Completed KLIS Scholarship Application Form '
					  }
					</div>
					<div className='engText' key='engText4'>
					  {
					    lang ? '2.一份个人陈述，关于申请者在所选领域经历的对学习的投入、受到的启发、面对的挑战和取得的成就（500 到 1000 字）；'
					      : '2.A personal statement about the commitment, inspiration, challenges, and achievements the candidate has experienced through the chosen discipline(s) (500 to 1000 words). '
					  }
					</div>
					<div className='engText' key='engText4'>
					  {
					    lang ? '3.学校成绩单，以及可以证明所选领域优异表现的奖项、证书或其他证明材料；a)过去两个学年的完整成绩单；b)当前学年第一学期的成绩单；c)当前学年第二学期阶段性成绩单；d)若所选领域适用，可提交相应作品集，例如：视觉艺术、设计、创新发明、表演视频、社区服务等4.推荐信；a)两封现任老师或过去两年授课老师的推荐信；b)一封来自于现学校领导团队的推荐信；'
					      : ' 3.Copies of transcripts, awards, certificates, or other recognitions pertaining to area(s) above.a.Previous 2 Year(s) Official Transcriptb.Current Year Semester 1 Transcriptc.Up to date Progress Report of Semester 2d.A portfolio where appropriate, e.g. visual arts, design, innovation, video of performance, community service4.Recommendation Lettersa.Two from current or past(2yrs) teachersb.One from a member of current school Leadership Team '
					  }
					</div>
					
					
					
					
					
					<div className='engTitle' key='engText5'>
						{
						  lang ? '在读学生需要提交评估的材料'
							: 'Current Student Items Required for Submission and Consideration'
						}
					</div>
					<div className='engText' key='engText5'>
					  {
					    lang ? '1.已填写完整的诺林巴蜀奖学金申请表'
					      : 'Completed KLIS Scholarship Application Form'
					  }
					</div>
					<div className='engText' key='engText5'>
					  {
					    lang ? '2.一份个人陈述，关于申请者在所选领域经历的对学习的投入、受到的启发、面对的挑战和取得的成就（500 到 1000 字）；'
					      : 'A personal statement about the commitment, inspiration, challenges, and achievements the candidate has experienced through the chosen discipline(s) (500 to 1000 words).'
					  }
					</div>
					<div className='engText' key='engText5'>
					  {
					    lang ? '3.学校成绩单，以及可以证明所选领域优异表现的奖项、证书或其他证明材料；a)过去两个学年的完整成绩单；b)当前学年第一学期的成绩单；c)当前学年第二学期阶段性成绩单；d)若所选领域适用，可提交相应作品集，例如：视觉艺术、设计、创新发明、表演视频、社区服务等'
					      : '3.Copies of transcripts, awards, certificates, or other recognitions pertaining to area(s) above.a.Previous 2 Year(s) Official Transcriptb.Current Year Semester 1 Transcript c.Up to date Progress Report of Semester 2.d.A portfolio where appropriate, e.g. visual arts, design, innovation, video of performance, community service'
					  }
					</div>
					<div className='engText' key='engText5'>
					  {
					    lang ? '入围的申请者可能会被邀请参加与诺林巴蜀奖学金委员会的面试。奖学金等级将由诺林巴蜀奖学金委员会决定。'
					      : 'Shortlisted candidates may be invited to interview with the KLIS Scholarship Committee. Scholarship awards will be announced at the discretion of the KLIS Scholarship Committee.'
					  }
					</div>
					
					
					<div className='engTitle' key='engText6'>
					  {
					    lang ? '新生及在读学生可以通过以下途径申请奖学金：'
					      : 'Both current and new students can apply for KLIS Scholarship by:'
					  }
					</div>
					<div className='engText' key='engText6'>
					  {
					    lang ? '1.点击此处链接 https://www.wjx.cn/vm/rqR9ULT.aspx#或扫描下方二维码填写'
					      : '1.Downloading Application Form for KLIS Scholarship from school website.'
					  }
					</div>
					<div className='engText' key='engText6'>
					  {
					    lang ? ''
					      : ' a.Click the link to apply https://www.wjx.cn/vm/rqR9ULT.aspx#'
					  }
					</div>
					<div className='engText' key='engText6'>
					  {
					    lang ? ''
					      : 'b.Or scan the QR code;'
					  }
					</div>
					<img src={j_ewm} alt="" className='j_ewm' key='j_ewm' />
					<div className='engText' key='engText6'>
					  {
					    lang ? '2.于2023年6月1日前提交已填写完整的申请表和其他材料；提交方式可以发送电子档至admissions@klschool.org或将纸质文件交至学校招生办公室。'
					      : '2.Submit the Form and all supporting documents by email (admissions@klschool.org) or by hardcopy to the Admissions Office before the application deadline of Jun 1st, 2023.  '
					  }
					</div>
					
        </QueueAnim>
      </OverPack>
    </div>
  )
};

export default connect (
  state => ({
    lang: state['lang'],
  })
)(Eng);