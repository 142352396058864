
/* 
  px和rem单位互转
  content: 样式表的内容
  unit: 需要替换的单位名称
  updateUnit: 替换后的单位名称
  rule: 
    单位换算规则，多少rem = px || px = rem
    例如：当rule为100时即表示 1rem = 100px
*/

export const pxAndRemInterturn = (content,unit,updateUnit,rule) => {
    rule = rule || 54
    if(!rule||!content)return content;
    const reg = {
      px : /[\:]*[\s]*[-]*[\s]*[0-9]*[\.]*[0-9]*\p\x/g,
      rem: /[\:]*[\s]*[-]*[\s]*[0-9]*[\.]*[0-9]*rem/g,
      number: /[0-9]*[\.]*[0-9]*/g
    }
    // 获取到所有匹配的内容
    const res = content.match(reg[unit])
    // 传进来的是正则匹配的结构内容
    let rightData = []
    if(!res){
      return content
    }
    for(let  i = 0; i <res.length;i++){
      rightData.push(Number(res[i].replace(new RegExp(' ', "gm"), '').replace(':','').replace(unit,'')))
    }
    for(let i=0;i < rightData.length;i++){
      // 单位换算
      let txt = ''
      let num = rightData[i]
      if(unit === 'px' && updateUnit === 'rem'){
        txt = num / rule
      }else if(unit === 'rem' && updateUnit === 'px'){
        txt = num * rule
      }
      txt = ' ' + txt + updateUnit
      
      if(res[i].indexOf(':') >= 0){
        txt = ': '+ txt
      }
      content = content.replace(res[i],txt)
    }
    return content
}