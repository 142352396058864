import Index from '@page/Index';

import Team from '@page/About/Team/Team';
import NewTeam from '@page/About/NewTeam/NewTeam';
import TeamDetail from '@page/About/TeamDetail/TeamDetail';
import Contact from '@page/About/Contact/Contact';
import Korea from '@page/About/Korea/Korea';
import Purpose from '@page/About/Purpose/Purpose';
import Principal from '@page/About/Principal/Principal';
import Certificate from '@page/About/Certificate/Certificate';
import JoinUs from '@page/About/JoinUs/JoinUs';


import CourseSystem from '@page/Learn/CourseSystem/CourseSystem';
import SysDetail from '@page/Learn/SysDetail/SysDetail';
import Plan from '@page/Learn/Plan/Plan';
import Map from '@page/Learn/Map/Map';
import Eng from '@page/Learn/Eng/Eng';
import China from '@page/Learn/China/China';
import Ship from '@page/Learn/Ship/Ship';
import Camp from '@page/Learn/Camp/Camp';
import Message from '@page/Learn/Message/Message';

import Apply from '@page/Apply/Apply';
import Money from '@page/Apply/Money/Money';




import Club from '@page/Life/Club/Club';
import Pat from '@page/Life/Pat/Pat';
import Cater from '@page/Life/Cater/Cater';
import Bus from '@page/Life/Bus/Bus';
import Health from '@page/Life/Health/Health';
import Calendar from '@page/Life/Calendar/Calendar';

import News from '@page/Dynamic/News/News';
import NewsDetail from '@page/Dynamic/News/NewsDetail/NewsDetail';
import Resource from '@page/Dynamic/Resource/Resource';

import PrivacyPolicy from '@page/PrivacyPolicy/PrivacyPolicy';
const routes = [
  { path: '/', name: 'Index', Component: Index },
  { path: '/team', name: 'Team', Component: Team },
  { path: '/newteam', name: 'NewTeam', Component: NewTeam },
  { path: '/team/detail/:type', name: 'TeamDetail', Component: TeamDetail },
  { path: '/contact', name: 'Contact', Component: Contact },
  { path: '/korea', name: 'Korea', Component: Korea },
  { path: '/purpose', name: 'Purpose', Component: Purpose},
  { path: '/message', name: 'Message', Component: Message},
  { path: '/principal', name: 'Principal', Component: Principal},
  { path: '/certificate', name: 'Certificate', Component: Certificate},
  { path: '/join', name: 'JoinUs', Component: JoinUs},
  { path: '/system', name: 'CourseSystem', Component: CourseSystem },
  { path: '/system/detail/:id', name: 'SysDetail', Component: SysDetail },
  { path: '/plan', name: 'Plan', Component: Plan },
  { path: '/map', name: 'Map', Component: Map},
  { path: '/eng', name: 'Eng', Component: Eng},
  { path: '/china', name: 'China', Component: China},
  { path: '/scholarship', name: 'Scholarship', Component: Ship},
  { path: '/camp', name: 'Camp', Component: Camp},
  
  { path: '/apply', name: 'Apply', Component: Apply },
  { path: '/feesandpolicies', name: 'feesandpolicies', Component: Money },
  
  { path: '/news', name: 'News', Component: News },
  { path: '/news/detail/:id', name: 'NewsDetail', Component: NewsDetail },
  { path: '/resource', name: 'Resource', Component: Resource },
  { path: '/club', name: 'Club', Component: Club },
  { path: '/pat', name: 'Pat', Component: Pat },
  { path: '/calendar', name: 'Calendar', Component: Calendar },
  { path: '/cater', name: 'Cater', Component: Cater },
  { path: '/bus', name: 'Bus', Component: Bus },
  { path: '/health', name: 'Health', Component: Health },
  { path: '/privacypolicy', name: 'PrivacyPolicy', Component: PrivacyPolicy},
]

export default routes;