import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './Calendar.less';
import b from '@img/about/zongBanner.png';
import calendarimg from '@img/life/calendar2021_2022.png';
import {Helmet} from "react-helmet";
import {GTM_ID} from '@/server/apiIp';
import {get, post} from '@/server/http';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);
const Calendar = ({ lang }) => {
    
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
    function onDocumentLoadFail(){
      console.log('fail')
    }
    
    let seo_data = localStorage.getItem('seo_data');
    const [detail, setDetail] = useState({});
    
    useEffect(() => {
      const trackPage = page => {
        ReactGA.set({
          page,
        });
        ReactGA.pageview(page);
      };
      trackPage(window.location.href)
      let _detail= {};
      const getDetail = get('/api/page/calendar').then(res => {
          _detail = res.data
          if(seo_data){
            seo_data = JSON.parse(seo_data)
            if(!_detail.seo_title){
              _detail.seo_title = seo_data.seo_title
            }
            if(!_detail.seo_keywords){
              _detail.seo_keywords = seo_data.seo_keywords
            }
            if(!_detail.seo_description){
              _detail.seo_description = seo_data.seo_description
            }
            if(!_detail.seo_en_title){
              _detail.seo_en_title = seo_data.seo_en_title
            }
            if(!_detail.seo_en_keywords){
              _detail.seo_en_keywords = seo_data.seo_en_keywords
            }
            if(!_detail.seo_en_description){
              _detail.seo_en_description = seo_data.seo_en_description
            }
          }
          console.log(_detail)
          setDetail(_detail)
      })
    }, []);

    return (
        <div className='calendarContent mainContBox'>
            <Helmet>
                <title>{lang?detail.seo_title:detail.seo_en_title}</title>
                <meta name="description" content={lang?detail.seo_description:detail.seo_en_description} />
                <meta name="keywords" content={lang?detail.seo_keywords:detail.seo_en_keywords} />
            </Helmet>
            <BannerItem src={lang?detail.calendar_banner_image:detail.calendar_en_banner_image} />
            <OverPack className='childContent' playScale={0.2}>
                <QueueAnim
                    type="bottom"
                    key="ul"
                    leaveReverse
                >
                    {
                        lang ? <Navtip title={['校园生活']} subtitle='校历' /> : <Navtip title={['School Life']} subtitle='School Calendar' />
                    }
                      
                    
                    {
                        // <img className='textImgBox' src={calendarimg}/>
                    }
                    <iframe src={lang?detail.calendar_file:detail.calendar_en_file} width="100%" height="680" ></iframe>
                    
                </QueueAnim>
            </OverPack>
        </div>
    )
};

export default connect(
    state => ({
        lang: state['lang']
    })
)(Calendar);