import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row, Col } from 'antd';
import s1 from '@img/home/s1.jpg';
import s2 from '@img/home/s2.png';
import './Article.less';
import { Link } from "react-router-dom";


const Article = ({lang,detail}) => {
  useEffect(() => {

  }, []);

  return (
    <OverPack className='mainContent' playScale={0.2} always={false}>
      <QueueAnim
        type="bottom"
        key="ul"
        leaveReverse
      >
        <Row className='margin-top-xl articleBox' key='r1'>
          <Col md={8} xs={24}>
            <div className='bText'>
              <div className='text'>{lang ? detail.index_windmill: detail.index_en_windmill}</div>
              <div className='btnReadBox'>
                <Link className='btn' to="/team">{lang ? '阅读更多' : 'Read more'}</Link>
              </div>
            </div>
          </Col>
          <Col md={11} xs={24} className='articleContent'>
            <div className='articleImg' style={{ background: `url(${s1}) no-repeat center`, backgroundSize: 'cover' }}></div>
          </Col>
          <Col md={5} xs={24} className='aTitleWrap'>
            <div className='aTitleBox'>
              <h1 className='name'>{ lang ? '学校团队' : 'Our People'}</h1>
              <div className='heng'></div>
              {
                // <div className='subtitle'>Our People</div>
              }
            </div>
          </Col>
        </Row>
        <Row className='margin-top-xl articleBox' key='r2'>
          <Col md={5} xs={24} className='aTitleWrap'>
            <div className='aTitleBox' style={{ float: 'left', textAlign: 'left' }}>
              <h1 className='name'>{lang ? '学术课程' : 'Academics'}</h1>
              <div className='heng'></div>
              {
                // <div className='subtitle'>Academics</div>
              }
            </div>
          </Col>
          <Col md={11} xs={24} className='articleContent'>
            <div className='articleImg' style={{ background: `url(${s2}) no-repeat center` }}></div>
          </Col>
          <Col md={8} xs={24}>
            <div className='bText'>
              <div className='text'>{lang ? '学校从幼儿园到12年级都使用的美国共同核心标准课程体系。共同核心是一套高质量的学术标准和学习目标，概述了学生在每个年级结束时应该知道和能够做的事情。这些标准由来自美国各地的专家和教师制定，旨在确保所有高中毕业的学生都具备在世界各地大学取得成功所需的技能和知识。' : 'KLIS uses the United States Common Core Standards from PreK through G12. The Common Core is a set of high-quality academic standard and learning goals that outline what a student should know and be able to do at the end of each grade. '}</div>
              <div className='btnReadBox textRight'>
                <Link className='btn' to="/system">{lang ? '阅读更多' : 'Read more'}</Link>
              </div>
            </div>
          </Col>
        </Row>
      </QueueAnim>
    </OverPack>
  )
}

export default connect(
    state => ({
        lang: state['lang'],
    })
)(Article);