import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import './Principal.less';
import xiao from '@img/about/xiao.png';
import {get, post} from '@/server/http';
import {Helmet} from "react-helmet";
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const Principal = ({ lang }) => {
  let seo_data = localStorage.getItem('seo_data');
  const [detail, setDetail] = useState({});
  useEffect(() => {
    const trackPage = page => {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    };
    trackPage(window.location.href)
    
    let _detail= {};
    const getDetail = get('/api/page/principal').then(res => {
        _detail = res.data
        // console.log(_detail)
        if(seo_data){
          seo_data = JSON.parse(seo_data)
          if(!_detail.seo_title){
            _detail.seo_title = seo_data.seo_title
          }
          if(!_detail.seo_keywords){
            _detail.seo_keywords = seo_data.seo_keywords
          }
          if(!_detail.seo_description){
            _detail.seo_description = seo_data.seo_description
          }
          if(!_detail.seo_en_title){
            _detail.seo_en_title = seo_data.seo_en_title
          }
          if(!_detail.seo_en_keywords){
            _detail.seo_en_keywords = seo_data.seo_en_keywords
          }
          if(!_detail.seo_en_description){
            _detail.seo_en_description = seo_data.seo_en_description
          }
        }
        setDetail(_detail)
    })
  }, []);
  return (
    <div className='PrincipalContent mainContBox'>
      <Helmet>
          <title>{lang?detail.seo_title:detail.seo_en_title}</title>
          <meta name="description" content={lang?detail.seo_description:detail.seo_en_description} />
          <meta name="keywords" content={lang?detail.seo_keywords:detail.seo_en_keywords} />
      </Helmet>
      <OverPack className='childContent' playScale={0.2}>
        <QueueAnim
          type="bottom"
          key="ul"
          leaveReverse
        >
            {
              lang ? (
              <Navtip title={['关于我们']} subtitle='校长寄语' />
              ):(
              <Navtip title={['About KLIS']} subtitle='Message From Principal' />
              )
            }
            <div className='xiaoContent'>
              <div className='xiaoBox'>
                <img src={detail.principal_left_image} alt="" />
              </div>
              <div className='textBox'>
                <div dangerouslySetInnerHTML={{__html: lang?detail.principal_content:detail.principal_en_content}}></div>
              </div>
            </div>
        </QueueAnim>
      </OverPack>
    </div>
  )
};

export default connect (
  state => ({
    lang: state['lang'],
  })
)(Principal);