import React from 'react';
import { connect } from 'react-redux';
import './BgShow.less';
import bg from '@img/home/bg.png';

const BgShow = ({ text, name }) => (
  <div className='margin-top-xl displayText box-translateY'   style={{ background: `url(${bg}) no-repeat center #4390d4`, backgroundSize: 'cover' }}>
    <div className='mainContent text'>
      {text}
      {
        !name ? '' : (
          <p className='textRight margin-top-l'>{name}</p>
        )
      }
    </div>
  </div>
);

export default connect(
    state => ({
        lang: state['lang'],
    })
)(BgShow);