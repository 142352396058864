import React from 'react';
import './BannerItem.less';


const BannerItem = (props) => {
  const { src } = props;
  return(
    // <div className="bannerItemBox" style={{ background: `url(${src}) no-repeat center -1rem`, backgroundSize: '100% auto' }}>
    // </div>
		<div className="bannerItemBox">
			<img src={src}/>
		</div>
  )
};

export default BannerItem;