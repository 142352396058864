import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './Ship.less';
import b from '@img/learn/jj.jpg';
import eng from '@img/system/eng.png'
import j_ewm from '@img/learn/j_ewm.jpg';
import j_show1 from '@img/learn/j_show1.jpg';
import j_show2 from '@img/learn/j_show2.jpg';
const Eng = ({ lang }) => {
  useEffect(() => {

  }, []);

  return (
    <div className='engContent mainContBox'>
      <BannerItem src={b} />
      <OverPack className='childContent' playScale={0.2}>
        <QueueAnim
          type="bottom"
          key="eng"
          leaveReverse
        >
          {
            lang ? <Navtip title={['学术课堂']} subtitle='优秀学生奖学金计划' /> : <Navtip title={['Academics']} subtitle='KLIS Scholarship' />
          }
          <img src={j_show1} alt="" className='j_show1' key='j_show1' />
					<div className='engTitle' key='engText1'>
						{
						  lang ? '申请资格'
							: 'Eligibility'
						}
					</div>
					<div className='engText' key='engText1'>
					  {
					    lang ? '2024-25学年五年级至十二年级的在读学生和新生。申请者必须中学全程在诺林巴蜀就读（从入学到十二年级毕业）。如果在毕业前已有搬离计划的国际学生，必须在申请期间披露入读期限。'
					      : 'New and Current Students G5-G12 in 2024-2025.The student must be fully committed to attend KLIS for the duration of their Secondary School studies (from admission to Grade 12 graduation). International students whose families are scheduled to be relocated prior to graduation must disclose the term of stay during the application period. '
					  }
					</div>
					
					<div className='engTitle' key='engText2'>
						{
						  lang ? '奖学金类别'
							: 'Scholarship Categories'
						}
					</div>
					<div className='engText' key='engText2'>
					  {
					    lang ? '铂金:  学费全额  '
					      : 'Platinum:  100% of Tuition;'
					  }
					</div>
					<div className='engText' key='engText2'>
					  {
					    lang ? ' 黄金:  学费半额  '
					      : 'Gold:  50% of Tuition;'
					  }
					</div>
					<div className='engText' key='engText2'>
					  {
					    lang ? ' 白银:  学费的30% '
					      : 'Silver: 30% of Tuition;'
					  }
					</div>
					<div className='engText' key='engText2'>
					  {
					    lang ? '学科领域单项奖：学费的20%'
					      : 'Subject & talent award：20% of Tuition'
					  }
					</div>
					
					<div className='engTitle' key='engText3'>
						{
						  lang ? '评定体系'
							: 'Overall Framework'
						}
					</div>
					<div className='engText' key='engText3'>
					  {
					    lang ? '学校将综合评估学生的申请和资料。评定小组将根据五个主要成就领域中的一个或多个，从定性和定量进行考虑和判断：'
					      : 'Your application and portfolio will be considered holistically. The panel will make its judgment based on qualitative and quantitative considerations under one or more of the five broad areas of achievement:  '
					  }
					</div>
					<div className='engText' key='engText3'>
					 {
					   lang ? '1.语言: 非母语语言特长'
					 	: '1.Language Arts: non-native language '
					 }
					</div>
					<div className='engText' key='engText3'>
					  {
					    lang ? '2.数学 / 科学 / 人文学科 / 技术学科'
					      : '2. Mathematics / Sciences / Humanities / Technology '
					  }
					</div>
					<div className='engText' key='engText3'>
					  {
					    lang ? '3.艺术学科: 视觉艺术，音乐'
					      : '3.The Arts: Visual Art, Music;'
					  }
					</div>
					<div className='engText' key='engText3'>
					  {
					    lang ? '4.运动与行动: 体育'
					      : '4. 	Sports and Action: Physical Education ;'
					  }
					</div>
					<div className='engText' key='engText3'>
					  {
					    lang ? '5.社区服务与领导力'
					      : '5. 	Service and Leadership '
					  }
					</div>
					
					
					
					<div className='engTitle' key='engText4'>
						{
						  lang ? '新生需要提交评估的材料'
							: 'New Student Items Required for Submission and Consideration'
						}
					</div>
					<div className='engText' key='engText4'>
					  {
					    lang ? '1.已填写完整的诺林巴蜀奖学金申请表；'
					      : '1.Completed KLIS Scholarship Application Form '
					  }
					</div>
					<div className='engText' key='engText4'>
					  {
					    lang ? '2.一份个人陈述，关于申请者在所选领域经历的对学习的投入、受到的启发、面对的挑战和取得的成就（500 到 1000 字）；'
					      : '2.A personal statement about the commitment, inspiration, challenges, and achievements the candidate has experienced through the chosen discipline(s) (500 to 1000 words). '
					  }
					</div>
					<div className='engText' key='engText4'>
					  {
					    lang ? '3.学校成绩单，以及可以证明所选领域优异表现的奖项、证书或其他证明材料；a)过去两个学年的完整成绩单；b)当前学年第一学期的成绩单；c)当前学年第二学期阶段性成绩单；d)若所选领域适用，可提交相应作品集，例如：视觉艺术、设计、创新发明、表演视频、社区服务等4.推荐信；a)两封现任老师或过去两年授课老师的推荐信；b)一封来自于现学校领导团队的推荐信；'
					      : ' 3.Copies of transcripts, awards, certificates, or other recognitions pertaining to area(s) above.a.Previous 2 Year(s) Official Transcriptb.Current Year Semester 1 Transcriptc.Up to date Progress Report of Semester 2d.A portfolio where appropriate, e.g. visual arts, design, innovation, video of performance, community service4.Recommendation Lettersa.Two from current or past(2yrs) teachersb.One from a member of current school Leadership Team '
					  }
					</div>
					
					
					
					
					
					
					<div className='engTitle' key='engText5'>
						{
						  lang ? '在读学生需要提交评估的材料'
							: 'Current Student Items Required for Submission and Consideration'
						}
					</div>
					<div className='engText' key='engText5'>
					  {
					    lang ? '1.已填写完整的诺林巴蜀奖学金申请表'
					      : 'Completed KLIS Scholarship Application Form'
					  }
					</div>
					<div className='engText' key='engText5'>
					  {
					    lang ? '2.一份个人陈述，关于申请者在所选领域经历的对学习的投入、受到的启发、面对的挑战和取得的成就（500 到 1000 字）；'
					      : 'A personal statement about the commitment, inspiration, challenges, and achievements the candidate has experienced through the chosen discipline(s) (500 to 1000 words).'
					  }
					</div>
					<div className='engText' key='engText5'>
					  {
					    lang ? '3.学校成绩单，以及可以证明所选领域优异表现的奖项、证书或其他证明材料；a)过去两个学年的完整成绩单；b)当前学年第一学期的成绩单；c)当前学年第二学期阶段性成绩单；d)若所选领域适用，可提交相应作品集，例如：视觉艺术、设计、创新发明、表演视频、社区服务等'
					      : '3.Copies of transcripts, awards, certificates, or other recognitions pertaining to area(s) above.a.Previous 2 Year(s) Official Transcriptb.Current Year Semester 1 Transcript c.Up to date Progress Report of Semester 2.d.A portfolio where appropriate, e.g. visual arts, design, innovation, video of performance, community service'
					  }
					</div>
					<div className='engText' key='engText5'>
					  {
					    lang ? '入围的申请者可能会被邀请参加与诺林巴蜀奖学金委员会的面试。奖学金等级将由诺林巴蜀奖学金委员会决定。'
					      : 'Shortlisted candidates may be invited to interview with the KLIS Scholarship Committee. Scholarship awards will be announced at the discretion of the KLIS Scholarship Committee.'
					  }
					</div>
					
					
					<div className='engTitle' key='engText6'>
					  {
					    lang ? '新生及在读学生可以通过以下途径申请奖学金：'
					      : 'Both current and new students can apply for KLIS Scholarship by:'
					  }
					</div>
					<div className='engText' key='engText6'>
					  {
					    lang ? '1.进入链接填写奖学金申请表 https://www.wjx.cn/vm/rqR9ULT.aspx#'
					      : '1.1.Click here to fill in the application form: https://www.wjx.cn/vm/QOHaIKV.aspx# '
					  }
					</div>
					<div className='engText' key='engText6'>
					  {
					    lang ? ''
					      : ' a.Click the link to apply https://www.wjx.cn/vm/rqR9ULT.aspx#'
					  }
					</div>
					
					<div className='engText' key='engText6'>
					  {
					    lang ? '2.于2024年6月15日前提交已填写完整的申请表和其他材料；提交方式可以发送电子档至admissions@klschool.org或将纸质文件交至学校招生办公室。'
					      : '2.Submit the Form and all supporting documents by email (admissions@klschool.org) or by hardcopy to the Admissions Office before the application deadline Jun 15th, 2024.  '
					  }
					</div>
					
        </QueueAnim>
      </OverPack>
    </div>
  )
};

export default connect (
  state => ({
    lang: state['lang'],
  })
)(Eng);