import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './Plan.less';
import b from '@img/system/planBanner.png';
import c from '@img/system/planMain.png';
import {get, post} from '@/server/http';
import {pxAndRemInterturn} from '@/server/util';
import {Helmet} from "react-helmet";
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const Plan = ({ lang }) => {
  let seo_data = localStorage.getItem('seo_data');
  const [detail, setDetail] = useState({});
  useEffect(() => {
    const trackPage = page => {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    };
    trackPage(window.location.href)
    
   let _detail= {};
   const getDetail = get('/api/page/plan').then(res => {
       _detail = res.data
       var plan_content = _detail.plan_content
       var plan_en_content = _detail.plan_en_content
       var regex = new RegExp('<img', 'gi');
       _detail.plan_content = plan_content.replace(regex, '<img style="width: 100%;"');
       _detail.plan_en_content = plan_en_content.replace(regex, '<img style="width: 100%;"');
       //px转rem
       _detail.plan_content = pxAndRemInterturn(_detail.plan_content,'px','rem')
       _detail.plan_en_content = pxAndRemInterturn(_detail.plan_en_content,'px','rem')
       if(seo_data){
         seo_data = JSON.parse(seo_data)
         if(!_detail.seo_title){
           _detail.seo_title = seo_data.seo_title
         }
         if(!_detail.seo_keywords){
           _detail.seo_keywords = seo_data.seo_keywords
         }
         if(!_detail.seo_description){
           _detail.seo_description = seo_data.seo_description
         }
         if(!_detail.seo_en_title){
           _detail.seo_en_title = seo_data.seo_en_title
         }
         if(!_detail.seo_en_keywords){
           _detail.seo_en_keywords = seo_data.seo_en_keywords
         }
         if(!_detail.seo_en_description){
           _detail.seo_en_description = seo_data.seo_en_description
         }
       }
       // console.log(_detail)
       setDetail(_detail)
   })
  }, []);

  return (
    <div className='planContent mainContBox'>
      <Helmet>
          <title>{lang?detail.seo_title:detail.seo_en_title}</title>
          <meta name="description" content={lang?detail.seo_description:detail.seo_en_description} />
          <meta name="keywords" content={lang?detail.seo_keywords:detail.seo_en_keywords} />
      </Helmet>
      <BannerItem src={lang ? detail.plan_banner_image:detail.plan_en_banner_image} />
      <OverPack className='childContent' playScale={0.2}>
        <QueueAnim
          type="bottom"
          key="plan"
          leaveReverse
        >
          <Navtip title={[lang?'学术课堂':'Academics']} subtitle={lang ? '升学及职业规划' : 'University counselling & career planning'}/>
          <div dangerouslySetInnerHTML={{__html: lang?detail.plan_content:detail.plan_en_content}}></div>
        </QueueAnim>
      </OverPack>
    </div>
  )
};

export default connect (
  state => ({
    lang: state['lang'],
  })
)(Plan);