import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './Bus.less';
import b from '@img/life/busBanner.png';
import m from '@img/life/busMain.png';
import line1 from '@img/life/line1.jpg';
import line2 from '@img/life/line2.jpg';
import line3 from '@img/life/line3.jpg';
import line4 from '@img/life/line4.jpg';
import line5 from '@img/life/line5.jpg';
import line6 from '@img/life/line6.jpg';
import busMap from '@img/life/busMap.jpeg';
import {Helmet} from "react-helmet";
import {get, post} from '@/server/http';
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const Bus = ({ lang }) => {
    let seo_data = localStorage.getItem('seo_data');
    const [detail, setDetail] = useState({});
    useEffect(() => {
      const trackPage = page => {
        ReactGA.set({
          page,
        });
        ReactGA.pageview(page);
      };
      trackPage(window.location.href)
      
     let _detail= {};
     const getDetail = get('/api/page/bus').then(res => {
         _detail = res.data
         var bus_content = _detail.bus_content
         var bus_en_content = _detail.bus_en_content
         var regex = new RegExp('<img', 'gi');
         _detail.bus_content = bus_content.replace(regex, '<img style="width: 100%;"');
         _detail.bus_en_content = bus_en_content.replace(regex, '<img style="width: 100%;"');
         if(seo_data){
           seo_data = JSON.parse(seo_data)
           if(!_detail.seo_title){
             _detail.seo_title = seo_data.seo_title
           }
           if(!_detail.seo_keywords){
             _detail.seo_keywords = seo_data.seo_keywords
           }
           if(!_detail.seo_description){
             _detail.seo_description = seo_data.seo_description
           }
           if(!_detail.seo_en_title){
             _detail.seo_en_title = seo_data.seo_en_title
           }
           if(!_detail.seo_en_keywords){
             _detail.seo_en_keywords = seo_data.seo_en_keywords
           }
           if(!_detail.seo_en_description){
             _detail.seo_en_description = seo_data.seo_en_description
           }
         }
         // console.log(_detail)
         setDetail(_detail)
     })
    }, []);

  return (
    <div className='busContent'>
      <Helmet>
          <title>{lang?detail.seo_title:detail.seo_en_title}</title>
          <meta name="description" content={lang?detail.seo_description:detail.seo_en_description} />
          <meta name="keywords" content={lang?detail.seo_keywords:detail.seo_en_keywords} />
      </Helmet>
      <BannerItem src={lang?detail.bus_banner_image:detail.bus_en_banner_image} />
      <OverPack className='childContent' playScale={0.2}>
        <QueueAnim
          type="bottom"
          key="ul"
          leaveReverse
        >
            {
                        lang ? <Navtip title={['校园生活', '校园服务']} subtitle='校巴' /> : <Navtip title={['School Life', 'School Services']} subtitle='School Bus' />
                    }

          {
                       
             <div className='textBox'>
                <div dangerouslySetInnerHTML={{__html: lang?detail.bus_content:detail.bus_en_content}}></div>
             </div>
          }

					
        </QueueAnim>
      </OverPack>
    </div>
  )
};

export default connect (
  state => ({
    lang: state['lang'],
  })
)(Bus);