import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './NewsDetail.less';
import b from '@img/about/joinBanner.png';
import b_en from '@img/about/joinBannerEn.png';
import life from '@img/about/life.png';
import fee from '@img/about/fee.png';
import {get, post} from '@/server/http';
import {pxAndRemInterturn} from '@/server/util';
import {Helmet} from "react-helmet";
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const NewsDetail = ({ lang }) => {
  let seo_data = localStorage.getItem('seo_data');
  const { id } = useParams();
  const [detail, setDetail] = useState({});
  useEffect(() => {
    const trackPage = page => {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    };
    trackPage(window.location.href)
    
    
    let _detail= {};
    const getDetail = get('/api/news/detail',{id:id}).then(res => {
        _detail = res.data.detail
        var content = _detail.content
        var en_content = _detail.en_content
        var regex = new RegExp('<img', 'gi');
        _detail.content = content.replace(regex, '<img style="width: 100%;"');
        _detail.en_content = en_content.replace(regex, '<img style="width: 100%;"');
        //px转rem
        _detail.content = pxAndRemInterturn(_detail.content,'px','rem')
        _detail.en_content = pxAndRemInterturn(_detail.en_content,'px','rem')
				console.log(_detail)
				if(seo_data){
				  seo_data = JSON.parse(seo_data)
				  if(!_detail.seo_title){
				    _detail.seo_title = seo_data.seo_title
				  }
				  if(!_detail.seo_keywords){
				    _detail.seo_keywords = seo_data.seo_keywords
				  }
				  if(!_detail.seo_description){
				    _detail.seo_description = seo_data.seo_description
				  }
				  if(!_detail.seo_en_title){
				    _detail.seo_en_title = seo_data.seo_en_title
				  }
				  if(!_detail.seo_en_keywords){
				    _detail.seo_en_keywords = seo_data.seo_en_keywords
				  }
				  if(!_detail.seo_en_description){
				    _detail.seo_en_description = seo_data.seo_en_description
				  }
				}
        setDetail(_detail)
    })
  }, []);

  return (
    <div className='mainContBox joinContent'>
      <Helmet>
          <title>{lang?detail.seo_title:detail.seo_en_title}</title>
          <meta name="description" content={lang?detail.seo_description:detail.seo_en_description} />
          <meta name="keywords" content={lang?detail.seo_keywords:detail.seo_en_keywords} />
      </Helmet>
      {
        // <BannerItem src={lang?b:b_en} />
      }
      
      <OverPack className='childContent' playScale={0.2}>
        <QueueAnim
          type="bottom"
          key="ul"
          leaveReverse
        >
				
        {
         <>
          <Navtip title={[lang?'最新动态':'About KLIS']} url={'/news'} subtitle={lang?'学校新闻':'School News'} />
          <div className='pageTitle'>{lang?detail.title:detail.en_title}</div>
          <div className='textBox'>
            <div dangerouslySetInnerHTML={{__html: lang?detail.content:detail.en_content}}></div>
          </div>

          </>
          
					
        }
        </QueueAnim>
      </OverPack>
    </div>
  )
};

export default connect (
  state => ({
    lang: state['lang'],
  })
)(NewsDetail);