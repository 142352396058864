import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './Purpose.less';
import b from '@img/about/zongBanner.png';
import aboutus from '@img/about/aboutus.png';
import {get, post} from '@/server/http';
import {Helmet} from "react-helmet";
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const Purpose = ({ lang }) => {
  let seo_data = localStorage.getItem('seo_data');
  const [detail, setDetail] = useState({});
  useEffect(() => {
    const trackPage = page => {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    };
    trackPage(window.location.href)
    
    let _detail= {};
    const getDetail = get('/api/page/purpose').then(res => {
        _detail = res.data
        var purpose_content = _detail.purpose_content
        var purpose_en_content = _detail.purpose_en_content
        var regex = new RegExp('<img', 'gi');
        _detail.purpose_content = purpose_content.replace(regex, '<img style="width: 100%;"');
        _detail.purpose_en_content = purpose_en_content.replace(regex, '<img style="width: 100%;"');
        // console.log(_detail)
        if(seo_data){
          seo_data = JSON.parse(seo_data)
          if(!_detail.seo_title){
            _detail.seo_title = seo_data.seo_title
          }
          if(!_detail.seo_keywords){
            _detail.seo_keywords = seo_data.seo_keywords
          }
          if(!_detail.seo_description){
            _detail.seo_description = seo_data.seo_description
          }
          if(!_detail.seo_en_title){
            _detail.seo_en_title = seo_data.seo_en_title
          }
          if(!_detail.seo_en_keywords){
            _detail.seo_en_keywords = seo_data.seo_en_keywords
          }
          if(!_detail.seo_en_description){
            _detail.seo_en_description = seo_data.seo_en_description
          }
        }
        setDetail(_detail)
        // document.title = "AAAAAAAA";
        // document.querySelector('meta[name="description"]').setAttribute('content','BBBBBB');
        // document.querySelector('meta[name="keywords"]').setAttribute('content','CCCCCCCCC');
    })
  }, []);

  return (
   
    <div className='purposeContent mainContBox'>
      <Helmet>
          <title>{lang?detail.seo_title:detail.seo_en_title}</title>
          <meta name="description" content={lang?detail.seo_description:detail.seo_en_description} />
          <meta name="keywords" content={lang?detail.seo_keywords:detail.seo_en_keywords} />
      </Helmet>
      <BannerItem src={lang?detail.purpose_banner_image:detail.purpose_en_banner_image} />
      <div>
        <OverPack className='childContent' playScale={0.2}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
          >
            <Navtip title={[lang?'关于我们':'About KLIS']} subtitle={lang?'学校介绍':'Guiding Statements'} key='purpose1'/>
            
            <div className='textBox' key='purpose2'>
            
              <div dangerouslySetInnerHTML={{__html: lang?detail.purpose_content:detail.purpose_en_content}}></div>
              
            </div>

           
          </QueueAnim>
        </OverPack>
      </div>
    </div>
  )
};

export default connect(
    state => ({
        lang: state['lang'],
    })
)(Purpose);