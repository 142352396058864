import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import './PrivacyPolicy.less';
import xiao from '@img/about/xiao.png';
import {Helmet} from "react-helmet";
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const PrivacyPolicy = ({ lang }) => {
  let seo_data = localStorage.getItem('seo_data');
  seo_data = JSON.parse(seo_data)
  useEffect(() => {
      const trackPage = page => {
        ReactGA.set({
          page,
        });
        ReactGA.pageview(page);
      };
      trackPage(window.location.href)
  }, []);

  return (
    <div className='PrivacyPolicyContent mainContBox'>
      <Helmet>
          <title>{lang?seo_data.seo_title:seo_data.seo_en_title}</title>
          <meta name="description" content={lang?seo_data.seo_description:seo_data.seo_en_description} />
          <meta name="keywords" content={lang?seo_data.seo_keywords:seo_data.seo_en_keywords} />
      </Helmet>
      <OverPack className='childContent' playScale={0.2}>
        <QueueAnim
          type="bottom"
          key="ul"
          leaveReverse
        >
          {
            lang ? (
              <>
                <Navtip  subtitle='隐私政策' />
                <div className='textBox'>
				  &#12288;&#12288;重庆市诺林巴蜀外籍人员子女学校（“我们”或“诺林巴蜀”）认真对待您的隐私。作为教育工作者，我们深知信任的重要性。我们制定了此项隐私政策，旨在让您知悉我们是如何收集、处理、使用、存储、共享、转让和披露您通过本网站提供给我们的任何个人信息（请见下文定义）。
				  本隐私政策适用于重庆市诺林巴蜀外籍人员子女学校官方网站（www.klisedu.com）。烦请您在使用我们的服务前仔细阅读并了解本隐私政策，通过您点击“我已阅读并确认同意本隐私政策”按钮，并继续访问本网站和使用网站上的功能，包括但不限于填写在线表格和提供申请信息，即表示您已经充分了解和同意本隐私政策的条款，并同意授权我们按照本隐私政策收集、处理、使用、存储、共享、转让或披露您提供的个人信息。若您不同意本隐私政策的内容，请您停止使用本网站。
				  请您确保您有权力和完全民事行为能力，使用本网站和向我们提交本网站所要求提交的您提供的个人信息，并授权我们在本隐私政策所述范围内收集、处理、使用、存储、共享、转让和披露您提供的个人信息。如果您不具有上述权力或完全民事行为能力，请您立即停止使用本网站。
				  请您注意：我们可能会不时修订本隐私政策，并在网站刊载修订后的版本。我们会在修订生效前通过网站公告或其他适当的方式对变更内容作出特别提示。修订的隐私政策自注明的更新日期起取代修订前的隐私政策版本而产生相应的法律效力。未经您的同意，我们不会削减你按照本隐私政策所应享有的权利。若您不同意隐私政策届时修订后的任何内容，请您在修订后隐私政策生效前停止使用本网站。
				  个人信息保护<br/>
				  对所有您提供的个人信息我们都严格保密，并按照适用法律法规处理您的个人信息。
				  我们会按现有技术标准提供相应的安全措施来保护您提供的个人信息，提供合理的安全保障，尽力防止您提供的个人信息遭到未经授权的访问、披露、使用、毁损、丢失或泄漏。尽管有前述的安全措施，但同时也请您理解，使用网络本身有风险，不能保证网络上存在绝对的安全。
				  提供准确信息<br/>
				  您应该确保您提供的个人信息为真实和准确的，我们不承担因您填写信息不准确、无授权或不真实引发的一切法律责任。如果您在填写信息时涉及他人信息的，应当确保获得其适当同意和授权。
				  使用Cookies（浏览器缓存文本文件）及同类技术<br/>
				  当您访问本网站时，我们的服务器会自动检测并记录关于您的非可识别个人身份的信息，通常将包括您的域名和位置、您访问的页面、您采用的检索词和搜索引擎以及您使用的网络浏览器。我们使用这些信息来监控我们网站的使用情况并且寻求改进方法。
				  本网站使用cookies。Cookies是我们存储在您计算机或移动设备上的一种简单的文本文件。这些cookies让我们能够存储您的相关信息，以便日后在您访问我们网站时，我们可以访问此类信息，以便我们为您提供更好用户体验的服务。我们设置的cookies是Session Cookies（来自网站本身）和XSRF-Token cookies（为防止XSS攻击）。
				  我们可能会不时使用定向或广告cookies，在第三方平台和网站上发布我们认为与您和您的兴趣更相关的广告。这些cookies会记住您在我们网站上看到的内容，我们可能会与各种第三方共享这些信息，以便在选定的第三方平台上提供有针对性的广告。
				  如果您希望限制或删除您计算机上已有的cookies，可以通过浏览器进行这一操作。有关cookies以及其管理方法的更多信息，请访问www.allaboutcookies.org。
				  未成年人<br/>
				  本网站供18周岁及以上用户使用。18周岁以下未成年人应当在父母或监护人的陪同下浏览本网站。任何时候当我们通过本网站收集18周岁以下儿童的个人信息时，会事先征得父母或监护人的同意。
				  个人信息收集和使用<br/>
				  我们将收集、处理、使用、存储、共享、转让或披露您提供的个人信息。我们将在下列情况下通过本网站向您获取个人信息：
				  ·       当您通过本网站向我们发送关于诺林巴蜀的咨询；<br/>
				  ·       当您订阅关于诺林巴蜀通讯或其他信息；<br/>
				  ·       当您或您的小孩通过我们的门户网站申请重庆市诺林巴蜀外籍人员子女学校；<br/>
				  ·       当您要求预约开放日重庆市诺林巴蜀外籍人员子女学校或参观学校；以及<br/>
				  ·       当您或您的小孩申请任何重庆市诺林巴蜀外籍人员子女学校联合举办或组织的任何活动。<br/>
				  如果您不披露您的个人信息，我们可能无法向您提供您所要求的服务。<br/>
				  “个人信息” 是指以电子或其他方式记录的，能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。这些信息可能包括姓名、出生日期、联系信息和其他入学所需的信息，例如国籍、地址、身份证号、签证、居住地、语言、教育信息、医疗信息、个人兴趣、饮食需求、学习和德育需求，以及个人偏好和成就。
				  个人信息存储<br/>
				  原则上，您的个人信息将存储在信息收集地所属的法律管辖地，但由于我们可能通过您居住地之外的资源和服务器为您提供服务，且基于重庆市诺林巴蜀外籍人员子女学校全球化运营和管理的需求，我们可能在适用法律法规允许范围内，将您提供的个人信息转移和储存至你所在法律管辖地以外的遍布全球的重庆市诺林巴蜀外籍人员子女学校及其接收方（定义见下文）的所在地。
				  为实现最初收集目的合理必要范围内，并基于适用法律和商业目的的要求，我们将继续存储您提供的个人信息。
				  个人信息使用和披露<br/>
				  1. 目的<br/>
				  收集及处理个人信息的目的包括但不限于以下目的:<br/>
				  ·       管理您和您孩子之间的关系（例如，回答您的咨询）；<br/>
				  ·       处理入学申请；<br/>
				  ·       举办重庆市诺林巴蜀外籍人员子女学校相关的活动（例如，开放日或参观学校）；<br/>
				  ·       遵守任何监管、审计或安全等相关要求；<br/>
				  ·       向您发送与诺林巴蜀相关的直接营销（例如，关于诺林巴蜀的时事通讯和广告）；<br/>
				  ·       与任一重庆市诺林巴蜀外籍人员子女学校顺利运营相关的其他目的。<br/>
				  2. 个人信息共享和传输<br/>
				  为完成本隐私政策所载目的并向您提供服务，我们可能会将您提供的个人信息传输给重庆市诺林巴蜀外籍人员子女学校以及其他接收方（定义见下文），该等接收方位于英国、美国、新加坡、中国（包括香港、澳门和台湾）、韩国、缅甸联邦共和国及其他接收方有运营的国家。我们只会基于本隐私政策和必要范围共享和传输您提供的个人信息，并会保证这些接收方提供的保护将不低于相关重庆市诺林巴蜀外籍人员子女学校运营所在地适用法律法规下的有关个人信息保护的标准。
				   “接收方”包括但不限于：教育机构、教育服务提供商、表演艺术机构、艺术组织、体育组织、重庆市诺林巴蜀外籍人员子女学校教育教学中引入的专家（个人或机构）、数据分析服务商、广告服务机构以及学校向您通知的其他产品和服务供应商。
				  不具身份识别性的个人信息<br/>
				  我们可能会对学业成绩和入学申请信息进行统计，并基于重庆市诺林巴蜀外籍人员子女学校运营分析的目的，与重庆市诺林巴蜀外籍人员子女学校及其他机构分享这些统计和分析数据或公开披露该等统计和分析数据，这些数据不包含您的任何个人信息。
				  除上述所允许情况之外，我们仅会在您同意，或者基于适用法律法规，向第三方或公众披露您的个人信息。
				  管理您的个人信息<br/>
				  若您希望访问或修改您提供的个人信息，或者基于适用的法律规定的原因要求我们删除您提供的个人信息，您可以按照本隐私政策所载联系方式联系我们进行访问、修改或删除。我们可能会要求您提供必要的身份证明，以验证您有权要求我们进行上述操作。
				  如果您希望修改或撤回您对收集和使用您个人信息的同意，您可以按照本隐私政策中的联系方式与我们联系。我们可能会要求您提供必要的身份证明，以验证您有权要求我们进行上述操作。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
				  若法律法规另有规定，我们可能会拒绝您的要求。对于某些请求，可能需要额外的时间来处理。即使应您要求我们删除了您提供的个人信息或您成功撤回您的同意，我们仍有权基于您之前的同意继续存储和使用经匿名化处理后的信息。经匿名化处理后的信息将不具有个人身份识别性。
				  您拥有随时选择退出直接推广的权力。您可通过本隐私政策中的联系信息与我们联系。<br/>
				  链接到其他网站<br/>
				  对于链接到本网站的第三方网站中的内容，以及从本网站链接到的第三方网站中的内容，我们概不承担任何责任，您查看此类网站需自行承担风险。
				  版权<br/>
				  本网站上的所有资料、设计、内容和信息在全世界范围内都受到版权法的保护。虽然您可以在一台电脑上下载或者打印本网站上的任何资料、设计、内容或信息以供您个人使用，但您不得在未征得我们明确许可的情况下，出于商业目的而复制、出售、发布、分发或转载本网站上的任何资料、设计、内容或信息。如需使用任何资料、设计、内容或信息，应首先将使用申请发送至marketing@klschool.org。
				  联系方式<br/>
				  如果您有任何疑问、意见、建议或要求，包括但不限于管理您提供的个人信息的要求，请通过发送邮件至admissions@klschool.org联系我们
                </div>
              </>
            ) : (
              <>
                <Navtip title={['About KLIS']} subtitle='Message From PrivacyPolicy' />
                
                <div className='textBox'>
                &#12288;&#12288;重庆市诺林巴蜀外籍人员子女学校（“我们”或“诺林巴蜀”）认真对待您的隐私。作为教育工作者，我们深知信任的重要性。我们制定了此项隐私政策，旨在让您知悉我们是如何收集、处理、使用、存储、共享、转让和披露您通过本网站提供给我们的任何个人信息（请见下文定义）。
                本隐私政策适用于重庆市诺林巴蜀外籍人员子女学校官方网站（www.klisedu.com）。烦请您在使用我们的服务前仔细阅读并了解本隐私政策，通过您点击“我已阅读并确认同意本隐私政策”按钮，并继续访问本网站和使用网站上的功能，包括但不限于填写在线表格和提供申请信息，即表示您已经充分了解和同意本隐私政策的条款，并同意授权我们按照本隐私政策收集、处理、使用、存储、共享、转让或披露您提供的个人信息。若您不同意本隐私政策的内容，请您停止使用本网站。
                请您确保您有权力和完全民事行为能力，使用本网站和向我们提交本网站所要求提交的您提供的个人信息，并授权我们在本隐私政策所述范围内收集、处理、使用、存储、共享、转让和披露您提供的个人信息。如果您不具有上述权力或完全民事行为能力，请您立即停止使用本网站。
                请您注意：我们可能会不时修订本隐私政策，并在网站刊载修订后的版本。我们会在修订生效前通过网站公告或其他适当的方式对变更内容作出特别提示。修订的隐私政策自注明的更新日期起取代修订前的隐私政策版本而产生相应的法律效力。未经您的同意，我们不会削减你按照本隐私政策所应享有的权利。若您不同意隐私政策届时修订后的任何内容，请您在修订后隐私政策生效前停止使用本网站。
                个人信息保护<br/>
                对所有您提供的个人信息我们都严格保密，并按照适用法律法规处理您的个人信息。
                我们会按现有技术标准提供相应的安全措施来保护您提供的个人信息，提供合理的安全保障，尽力防止您提供的个人信息遭到未经授权的访问、披露、使用、毁损、丢失或泄漏。尽管有前述的安全措施，但同时也请您理解，使用网络本身有风险，不能保证网络上存在绝对的安全。
                提供准确信息<br/>
                您应该确保您提供的个人信息为真实和准确的，我们不承担因您填写信息不准确、无授权或不真实引发的一切法律责任。如果您在填写信息时涉及他人信息的，应当确保获得其适当同意和授权。
                使用Cookies（浏览器缓存文本文件）及同类技术<br/>
                当您访问本网站时，我们的服务器会自动检测并记录关于您的非可识别个人身份的信息，通常将包括您的域名和位置、您访问的页面、您采用的检索词和搜索引擎以及您使用的网络浏览器。我们使用这些信息来监控我们网站的使用情况并且寻求改进方法。
                本网站使用cookies。Cookies是我们存储在您计算机或移动设备上的一种简单的文本文件。这些cookies让我们能够存储您的相关信息，以便日后在您访问我们网站时，我们可以访问此类信息，以便我们为您提供更好用户体验的服务。我们设置的cookies是Session Cookies（来自网站本身）和XSRF-Token cookies（为防止XSS攻击）。
                我们可能会不时使用定向或广告cookies，在第三方平台和网站上发布我们认为与您和您的兴趣更相关的广告。这些cookies会记住您在我们网站上看到的内容，我们可能会与各种第三方共享这些信息，以便在选定的第三方平台上提供有针对性的广告。
                如果您希望限制或删除您计算机上已有的cookies，可以通过浏览器进行这一操作。有关cookies以及其管理方法的更多信息，请访问www.allaboutcookies.org。
                未成年人<br/>
                本网站供18周岁及以上用户使用。18周岁以下未成年人应当在父母或监护人的陪同下浏览本网站。任何时候当我们通过本网站收集18周岁以下儿童的个人信息时，会事先征得父母或监护人的同意。
                个人信息收集和使用<br/>
                我们将收集、处理、使用、存储、共享、转让或披露您提供的个人信息。我们将在下列情况下通过本网站向您获取个人信息：
                ·       当您通过本网站向我们发送关于诺林巴蜀的咨询；<br/>
                ·       当您订阅关于诺林巴蜀通讯或其他信息；<br/>
                ·       当您或您的小孩通过我们的门户网站申请重庆市诺林巴蜀外籍人员子女学校；<br/>
                ·       当您要求预约开放日重庆市诺林巴蜀外籍人员子女学校或参观学校；以及<br/>
                ·       当您或您的小孩申请任何重庆市诺林巴蜀外籍人员子女学校联合举办或组织的任何活动。<br/>
                如果您不披露您的个人信息，我们可能无法向您提供您所要求的服务。<br/>
                “个人信息” 是指以电子或其他方式记录的，能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。这些信息可能包括姓名、出生日期、联系信息和其他入学所需的信息，例如国籍、地址、身份证号、签证、居住地、语言、教育信息、医疗信息、个人兴趣、饮食需求、学习和德育需求，以及个人偏好和成就。
                个人信息存储<br/>
                原则上，您的个人信息将存储在信息收集地所属的法律管辖地，但由于我们可能通过您居住地之外的资源和服务器为您提供服务，且基于重庆市诺林巴蜀外籍人员子女学校全球化运营和管理的需求，我们可能在适用法律法规允许范围内，将您提供的个人信息转移和储存至你所在法律管辖地以外的遍布全球的重庆市诺林巴蜀外籍人员子女学校及其接收方（定义见下文）的所在地。
                为实现最初收集目的合理必要范围内，并基于适用法律和商业目的的要求，我们将继续存储您提供的个人信息。
                个人信息使用和披露<br/>
                1. 目的<br/>
                收集及处理个人信息的目的包括但不限于以下目的:<br/>
                ·       管理您和您孩子之间的关系（例如，回答您的咨询）；<br/>
                ·       处理入学申请；<br/>
                ·       举办重庆市诺林巴蜀外籍人员子女学校相关的活动（例如，开放日或参观学校）；<br/>
                ·       遵守任何监管、审计或安全等相关要求；<br/>
                ·       向您发送与诺林巴蜀相关的直接营销（例如，关于诺林巴蜀的时事通讯和广告）；<br/>
                ·       与任一重庆市诺林巴蜀外籍人员子女学校顺利运营相关的其他目的。<br/>
                2. 个人信息共享和传输<br/>
                为完成本隐私政策所载目的并向您提供服务，我们可能会将您提供的个人信息传输给重庆市诺林巴蜀外籍人员子女学校以及其他接收方（定义见下文），该等接收方位于英国、美国、新加坡、中国（包括香港、澳门和台湾）、韩国、缅甸联邦共和国及其他接收方有运营的国家。我们只会基于本隐私政策和必要范围共享和传输您提供的个人信息，并会保证这些接收方提供的保护将不低于相关重庆市诺林巴蜀外籍人员子女学校运营所在地适用法律法规下的有关个人信息保护的标准。
                 “接收方”包括但不限于：教育机构、教育服务提供商、表演艺术机构、艺术组织、体育组织、重庆市诺林巴蜀外籍人员子女学校教育教学中引入的专家（个人或机构）、数据分析服务商、广告服务机构以及学校向您通知的其他产品和服务供应商。
                不具身份识别性的个人信息<br/>
                我们可能会对学业成绩和入学申请信息进行统计，并基于重庆市诺林巴蜀外籍人员子女学校运营分析的目的，与重庆市诺林巴蜀外籍人员子女学校及其他机构分享这些统计和分析数据或公开披露该等统计和分析数据，这些数据不包含您的任何个人信息。
                除上述所允许情况之外，我们仅会在您同意，或者基于适用法律法规，向第三方或公众披露您的个人信息。
                管理您的个人信息<br/>
                若您希望访问或修改您提供的个人信息，或者基于适用的法律规定的原因要求我们删除您提供的个人信息，您可以按照本隐私政策所载联系方式联系我们进行访问、修改或删除。我们可能会要求您提供必要的身份证明，以验证您有权要求我们进行上述操作。
                如果您希望修改或撤回您对收集和使用您个人信息的同意，您可以按照本隐私政策中的联系方式与我们联系。我们可能会要求您提供必要的身份证明，以验证您有权要求我们进行上述操作。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
                若法律法规另有规定，我们可能会拒绝您的要求。对于某些请求，可能需要额外的时间来处理。即使应您要求我们删除了您提供的个人信息或您成功撤回您的同意，我们仍有权基于您之前的同意继续存储和使用经匿名化处理后的信息。经匿名化处理后的信息将不具有个人身份识别性。
                您拥有随时选择退出直接推广的权力。您可通过本隐私政策中的联系信息与我们联系。<br/>
                链接到其他网站<br/>
                对于链接到本网站的第三方网站中的内容，以及从本网站链接到的第三方网站中的内容，我们概不承担任何责任，您查看此类网站需自行承担风险。
                版权<br/>
                本网站上的所有资料、设计、内容和信息在全世界范围内都受到版权法的保护。虽然您可以在一台电脑上下载或者打印本网站上的任何资料、设计、内容或信息以供您个人使用，但您不得在未征得我们明确许可的情况下，出于商业目的而复制、出售、发布、分发或转载本网站上的任何资料、设计、内容或信息。如需使用任何资料、设计、内容或信息，应首先将使用申请发送至marketing@klschool.org。
                联系方式<br/>
                如果您有任何疑问、意见、建议或要求，包括但不限于管理您提供的个人信息的要求，请通过发送邮件至admission@klschool.org联系我们
                      </div>
              </>
            )
          }
        </QueueAnim>
      </OverPack>
    </div>
  )
};

export default connect (
  state => ({
    lang: state['lang'],
  })
)(PrivacyPolicy);