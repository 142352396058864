import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row, Col } from 'antd';
import './News.less';
import n1 from '@img/home/n1.png';
import n2 from '@img/home/n2.png';
import n3 from '@img/home/n3.png';
import n4 from '@img/home/n4.png';
import n5 from '@img/home/n5.png';
import {get, post} from '@/server/http';

const news = [
  { img: n2, title: '新闻标题', date: '2021年10月30日', title_en: 'news title' ,date_en: '2021-10-30',},
  { img: n3, title: '新闻标题', date: '2021年10月30日', title_en: 'news title' ,date_en: '2021-10-30',},
  { img: n4, title: '新闻标题', date: '2021年10月30日', title_en: 'news title' ,date_en: '2021-10-30',},
  { img: n5, title: '新闻标题', date: '2021年10月30日', title_en: 'news title' ,date_en: '2021-10-30',}
];

const News = ({lang}) => {
  const [news, setNews] = useState([]);
  useEffect(() => {
    let _news= [];
    const getList = get('/api/home/newsList').then(res => {
        _news = res.data.list
        setNews(_news)
    })
  },[]);
  return (
    <OverPack className='mainContent newsBox box-translateX'   playScale={0.2} always={false}>
      <QueueAnim
        type="bottom"
        key="news"
        leaveReverse
        component={Row}
      >
        <Col md={12} xs={24} key="left">
        {
          news.map((t, i) => (
          
            i <3 && 
          <Link key='0' to={`/news/detail/${t.id}`}>
            <div className='new-left' style={{ background: `url(${t.image?t.image.file_path:''}) no-repeat center`, backgroundSize: 'cover' }}>
              
            </div>
			<div className='news_alti'>
				  <p className='date'>{t.push_time}</p>
				  <h1 className='title text-over'>{lang ? t.title : t.en_title}</h1>
			</div>
          </Link>
          
          ))
        }
          
        </Col>
		{/*
        <Col md={12} xs={24} key='right'>
          {
              news.map((t, i) => (
              
                i > 0 &&
                  <Col md={12} xs={24} key={i}>
                    <Link key='0' to={`/news/detail/${t.id}`}>
                      <div className='new-right-item' style={{ background: `url(${t.image?t.image.file_path:''}) no-repeat center`, backgroundSize: 'cover' }}>
                        <div className='newBox'>
                          <p className='date'>{t.push_time}</p>
                          <h1 className='title'>{lang ? t.title : t.en_title}</h1>
                        </div>
                      </div>
                    </Link>
                  </Col>
                  
              ))
          }
        </Col>
		*/}
      </QueueAnim>
      {
        
        <div className='btnReadBox'>
          <Link className='btn' to="/news">{lang ? '更多新闻' : 'Read more'}</Link>
        </div>
      }
    </OverPack>
  )
}

export default connect(
    state => ({
        lang: state['lang'],
    })
)(News);