import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './Message.less';
import b from '@img/learn/jj.jpg';
const Eng = ({ lang }) => {
  useEffect(() => {

  }, []);

  return (
    <div className='engContent mainContBox'>
      <BannerItem src={b} />
      <OverPack className='childContent' playScale={0.2}>
        <QueueAnim
          type="bottom"
          key="eng"
          leaveReverse
        >
          {
            lang ? <Navtip title={['关于我们']} subtitle='信息公示' /> : <Navtip title={['About Us']} subtitle='Message Notice' />
          }
					<div className='engTitle' key='engText1'>
						{
						  lang ? '学校历年审计报告防伪标识编码公示：'
							: 'Publicity of anti-counterfeiting identification codes in school audit reports over the years'
						}
					</div>
					<div className='engText' key='engText1'>
					  {
					    lang ? '2017年：480595991222 号'
					      : '2017: No. 480595991222 '
					  }
					</div>
					
					<div className='engText' key='engText1'>
					  {
					    lang ? '2018年：483642045654 号'
					      : '2018: No. 483642045654 '
					  }
					</div>
					<div className='engText' key='engText1'>
					  {
					    lang ? '2019年：264800106839 号'
					      : '2019: No. 264800106839 '
					  }
					</div>
					<div className='engText' key='engText1'>
					  {
					    lang ? '2020年：0210 4061 0070 0725 33'
					      : '2020: 0210 4061 0070 0725 33 '
					  }
					</div>
					<div className='engText' key='engText1'>
					  {
					    lang ? '2021年：0220 4021 0070 1004 10 '
					      : '2021: 0220 4021 0070 1004 10'
					  }
					</div>
					<div className='engText' key='engText1'>
					  {
					    lang ? '2022年：渝23DJKW9FRV'
					      : '2022: Yu 23DJKW9FRV'
					  }
					</div>
					
							
        </QueueAnim>
      </OverPack>
    </div>
  )
};

export default connect (
  state => ({
    lang: state['lang'],
  })
)(Eng);