import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import QueueAnim from 'rc-queue-anim';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row, Col } from 'antd';
import './Campus.less';
import m1 from '@img/home/m1.png';
import m2 from '@img/home/m2.jpg';
import m3 from '@img/home/m3.png';
import m4 from '@img/home/m4.jpg';
import xu1 from '@img/home/xu1.jpg';
import xu2 from '@img/home/xu2.jpg';
import xu3 from '@img/home/xu3.jpg';
import xu4 from '@img/home/xu4.jpg';
const mathImgs = [
  { src: m1, top: '', left: '' },
  { src: m2, top: '', left: '-80px' },
  { src: m3, top: '-80px', left: '' },
  { src: m4, top: '-80px', left: '-80px' },
];

const Campus = ({lang}) => (
	
	<div className='mainContent index_showall'> 
		<div className='index_showcom sp-arrow'id='wrapper' >
			<div className='index_showimg'>
				<div className='index_showline'><img className='lineImgBox' src={xu4}/></div>
			</div>
			<div className='index_showcon box-translate' ><p> {lang ? 'KLIS把学生放在第一位，我们帮助所有的学生发现他们对学习的热爱。我们致力于培养学生的好奇心和强烈的求知欲。我们坚信，培养学生的求知欲比仅仅专注于他们所学的东西更重要。KLIS的老师拥有优秀的教育学位和平均10年的教学经验。' : 'At KLIS, we stick to our core value"Student First". We help all students discover their love of learning.We are committed to nurturing curiosity and a strong thirst for knowledge. We firmly believe that nurturing students creativity& curiosity is more important than just focusing on what they have learned. KLIS teachers have excellent educational degrees and an average of 10 years of teaching experience.'}</p></div>
		</div>
		<div className='index_ssblshow clear'  >
			<div><img className='lineImgBox box-translateX'  src={xu1}/></div>
			<div><img className='lineImgBox box-translateX' src={xu2}/></div>
			<div><img className='lineImgBox box-translateX' src={xu3}/></div>
		</div>
  
   {/* <Row className='mainMath margin-top-xl'>
      <Col md={14} xs={24} className='contentCol'>
        <div className='mathimgBox'>
          {
            mathImgs.map(t => (
              <Col span={12} key={t.src}>
                <div
                  style={{ marginTop: t.top, marginLeft: t.left, background: `url(${t.src}) no-repeat center`, backgroundSize: 'cover' }}
                  className='mimgBox'
                >
                </div>
              </Col>
            ))
          }
        </div>
        <div className='centerCircle'>
          <div className='text'>
            CAMPUS
            <span className='insetText'>{lang ? '校园' : 'Campus'}</span>
          </div>
        </div>
      </Col>
      <Col md={10} xs={24}>
        <div className='mathText'>{lang ? '我们相信通过把学生放在第一位，我们帮助所有的学生发现他们对学习的热爱。我们相信帮助所有学生发展他们的内驱力。我们支持他们的成长，帮助他们成为最好的自己。我们致力于培养学生的好奇心和强烈的求知欲，注重学生的全面发展。我们坚信，培养学生的求知欲比仅仅专注于他们所学的东西更重要。' : 'KLIS teachers are passionate, committed and caring professionals, who nurture and guide each student in exploring their talents and fulfilling their dreams. 100% of our teachers are Chinese government certificated. KLIS employs highly qualified teachers with degrees in Education, with an average of 5 years of experience from America and internationally.'}</div>
      </Col>
    </Row>*/}
  </div>
);

export default connect(
    state => ({
        lang: state['lang'],
    })
)(Campus);