import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '@/store/actionCreators';
import { createBrowserHistory } from 'history';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Header from '@components/Header/Header';
import Footer from '@components/Footer/Footer';
import ScrollToTop from '@components/ScrollToTop/ScrollToTop';
import AnimatedRouter from 'react-animated-router';
import routes from './router';
import 'react-animated-router/animate.css';
import {get, post} from '@/server/http';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
class App extends Component {
  constructor(props) {
    super(props);
    localStorage.setItem('seo_data',JSON.stringify({title:''}));
    this.state = {
      isMobile,
    };
  }
  componentDidMount() {
    const _lang = localStorage.getItem('lang');
    if (_lang !== null) {
      this.props.setLangData(_lang === 'false' ? false : true);
    }
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    //获取全局tdk
    const settingData = get('/api/page/settingData').then(res => {
        let data = res.data
        localStorage.setItem('seo_data',JSON.stringify(data));
        const seo_data = localStorage.getItem('seo_data');
    })
    // settingData();
  }
  render() {
    return (
      <Router history={createBrowserHistory()}>
        <div>
          <Header isMobile={this.state.isMobile} history={createBrowserHistory()} />
          <div className={this.state.isMobile ? 'mobileEmptyDiv' : 'emptyDiv'}></div>
          <Switch>
            <ScrollToTop>
              <AnimatedRouter timeout={500}>
                {routes.map(({ path, Component }) => (
                  <Route key={path} exact path={path} component={(props) => <Component {...props}/>} />
                ))}
              </AnimatedRouter>
            </ScrollToTop>
          </Switch>
          <Footer isMobile={this.state.isMobile} />
        </div>
      </Router>
    );
  }
}

// 把store的Diapatch映射到组件的props
const mapDispatchToProps = (dispatch) => ({
  setLangData(data) {
    const action = actionCreators.setLangData(data)
    dispatch(action)
  }
})

export default connect (
  state => ({
    lang: state['lang'],
    isMobile:1
  }),
  mapDispatchToProps,
)(App);
