import React from 'react';
import TweenOne from 'rc-tween-one';
import { Menu, Icon, Drawer } from 'antd';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import * as actionCreators from '@/store/actionCreators';
import logo from '@img/home/logo.png';
import search from '@img/search.png';
import down from '@img/down.png';
import './Header.less';

const { SubMenu, Item } = Menu;

const navData = [
  // { name: '首页', en: 'Home', children: [], href: '/' },
  { name: '关于我们', en: 'About KLIS', children: [
    { name: '学校介绍', en: 'Guiding Statements', href: '/purpose'},
		{ name: '校长寄语', en: 'Message From Principal', href: '/principal'},
    { name: '学校团队', en: 'Our People', href: '/team', grandson: [
      { name: '管理团队', en: 'Leadership Team', href: '/team/detail/10'},
      { name: '学术团队', en: 'Academic Team', href: '/newteam'},
      { name: '支持团队', en: 'Supporting Team', href: '/team/detail/30'},
    ]},
	// { name: '新团队', en: 'NewTeam', href: '/newteam'},
    { name: '学校认证', en: 'Accreditations', href: '/certificate'},
    { name: '联系我们', en: 'Contact Us', href: '/contact'},
	// { name: '입학처', en: '입학처', href: '/korea'},
    { name: '职业发展', en: 'Careers', href: '/join'},
	// { name: '信息公示', en: 'Message', href: '/message'},
  ] },
  { name: '学术课程', en: 'Academics', children: [
		{ name: '课程体系', en: 'Curriculum', href: '/system', grandson: [
		  { name: '幼儿园', en: 'Early Years', href: '/system/detail/1'},
		  { name: '小学', en: 'Elementary School', href: '/system/detail/2'},
		  { name: '初中', en: 'Middle School', href: '/system/detail/3'},
		  { name: '高中', en: 'High School', href: '/system/detail/4'}
		]},
		{ name: 'MAP测评', en: 'MAP Testing', href: '/map'},
    { name: '英语强化支持', en: 'ESL Support', href: '/eng'},
		{ name: '升学及职业规划', en: 'University counselling & career planning', href: '/plan'},
    { name: '中国语言文化教育', en: 'Chinese Language & Culture', href: '/china'},
	// { name: '优秀学生奖学金计划', en: 'KLIS Scholarship', href: '/ship'},
		
  ] },
  { name: '校园生活', en: 'School Life', children: [
    { name: '选修和课后俱乐部', en: 'Electives and Clubs', href: '/club'},
    { name: '家委会', en: 'Parent Teacher Association (PTA)', href: '/pat'},
    { name: '校园服务', en: 'School Services', href: '', grandson: [
      { name: '餐食', en: 'Catering', href: '/cater'},
      { name: '校巴', en: 'School Bus', href: '/bus'},
      { name: '医护服务', en: 'Health Clinic', href: '/health'},
    ]},
    { name: '校历', en: 'School Calendar', href: '/calendar'},
  ] },
  { name: '最新动态', en: 'School Updates', children: [
    { name: '学校新闻', en: 'School News', href: '/news'},
    { name: '资源下载', en: 'Resources', href: '/resource'},
	{ name: '优秀学生奖学金计划', en: 'KLIS Scholarship', href: '/scholarship'},
  ] },
  { name: '申请入学', en: 'Admissions', children: [
	  { name: '申请入学', en: 'Admissions', href: '/apply' },
	  { name: '学费及费用', en: 'School Fees And Policies', href: '/feesandpolicies'},
	  // { name: '优秀学生奖学金计划', en: 'KLIS Scholarship', href: '/scholarship'},
	  
  ]},
  
];

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: false,
      defaulS: ['/'],
    };
  }

  phoneClick = () => {
    this.setState({
      phoneOpen: !this.state.phoneOpen
    });
  };

  setDetauls(hash) {
    if (hash) {
      const pathname = hash.split('/')[1];
      this.setState({
        defaulS: [`/${pathname}`]
      })
    }
  }

  componentDidMount() {
    const { location = {} } = this.props.history;
    console.log(location);
    const { hash = '' } = location;
    this.setDetauls(hash);

    this.props.history.listen(route => {
      const { location = {} } = route;
      const { hash = '' } = location;
      this.setDetauls(hash);
    })
  }

  render() {
    const { isMobile, lang } = this.props;
    const { phoneOpen, defaulS } = this.state;
    const navChildren = navData.map((t, i) => (
        t.children.length > 0 ? (
          <SubMenu
            key={i}
            title={
              <span style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <span>{lang ? t.name : t.en}</span>
                <img src={down} className="downIcon" alt="" />
              </span>
            }
          >
            {
              t.children.map(j => (
                <Item key={j.href}>
                  <Link
                    to={j.href === '' ? {javascript:void(0)} : j.href}
                    className='childrenA'
                  >
                    <span>{lang ? j.name : j.en}</span>
                    {
                      j.grandson && (
                        <div className='geandsonBox'>
                          {
                            j.grandson.map(s => (
                              <div className='sonItem' key={s.href}>
                                <Link to={s.href} className='sonA'>
                                  <span>{lang ? s.name : s.en}</span>
                                </Link>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }
                  </Link>
                </Item>
              ))
            }
          </SubMenu>
        ) : (
          <Item key={t.href}>
            <Link
              to={t.href}
            >
              {lang ? t.name : t.en}
            </Link>
          </Item>
        )
    ));
    const mobileNavChildren = navData.map((t,i) => (
      t.children && t.children.length > 0 ? (
        <SubMenu key={i}  title={lang ? t.name : t.en}>
          {
            t.children.map(j => (
                j.grandson && j.grandson.length > 0 ? (
                  <SubMenu key={j.href} title={lang ? j.name : j.en}>
                    {
                      j.grandson.map(s => (
                        <Menu.Item key={s.href}>
                          <Link to={s.href} className='sonA'>
                            <span>{lang ? s.name : s.en}</span>
                          </Link>
                        </Menu.Item>
                      ))
                    }
                  </SubMenu>
                ) : (
                  <Menu.Item key={j.href}>
                    <Link to={j.href}>
                      {lang ? j.name : j.en}
                    </Link>
                  </Menu.Item>
                )
              
            ))
          }
          
        </SubMenu>
      ) :(
        <Menu.Item key={t.href} >
         <Link to={t.href}>
           {lang ? t.name : t.en}
         </Link>
        </Menu.Item>
      )
    ))
    return (
      <div className="header-content">
        <TweenOne
          component="header"
          animation={{ opacity: 0, type: 'from' }}
          className='header0 home-page-wrapper'
        >
          <div
            className='header-log-top'
          >
            <div className="header-log-box">
              <TweenOne
                animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
                className='header0-logo'
              >
                <Link to="/"><img width="100%" src={logo} alt="img" /></Link>
              </TweenOne>
              <TweenOne
                animation={{ x: 30, type: 'from', ease: 'easeOutQuad' }}
              >
                <div className='headerTop-right'>
                  <span
                    className="langthSwitch"
                    onClick={() => {
                      this.props.setLangData(!lang);
                      localStorage.setItem('lang', !lang);
                    }}
                  >
				
				  <a className="gotoKorea" style={{color:'black',marginRight:'10px'}} target='_blank' href='https://korean.klisedu.com/'>{lang ? '한국어' : '한국어'}</a> 
					
                    {lang ? 'EN' : '中文'}
                  </span>
                  <div className='shu'></div>
                  {/*<Link to='/apply' className="btnCtrl btnFrst">{lang ? '入学申请' : 'Admissions'}</Link>*/}
				  
									<Link to='/join' className="btnCtrl btnFrst">{lang ? '职业发展' : 'Careers'}</Link>
                  <Link to='/contact' className="btnCtrl2">{lang ? '联系我们' : 'Contact Us'}</Link>
				  {/*<Link to='/korea' className="btnCtrl">{lang ? '입학처' : '입학처'}</Link>*/}
                  {/*<img src={search} alt="" className='searchImg' />*/}
                  {
                    isMobile && (
                      <Icon type='menu' className='menuPhone' onClick={() => this.phoneClick()} />
                    )
                  }
                </div>
              </TweenOne>
            </div>
          </div>
          <div
            className='home-page'
          >
            <TweenOne
              className='header0-menu'
              animation={{ x: 30, type: 'from', ease: 'easeOutQuad' }}
              ref={(c) => {
                this.menu = c;
              }}
            >
              {
                !isMobile && (
                  <Menu
                    mode='horizontal'
                    selectedKeys={defaulS}
                    onClick={(item) => {
                      const { keyPath } = item;
                      this.setState({
                        defaulS: keyPath
                      })
                    }}
                  >
                    {navChildren}
                  </Menu>
                )
              }
            </TweenOne>
          </div>
        </TweenOne>
        <Drawer
          title={lang ? '重庆诺林巴蜀外籍人员子女学校官网':'KL International School of Chongqing'}
          placement="right"
          onClose={this.phoneClick}
          visible={phoneOpen}
          className='phoneDraw'
        >
        {
          <Menu
            mode='inline'
            selectedKeys={defaulS}
            theme='light'
            // defaultOpenKeys={['0', '1', '2', '3', '4', '5']}
            onClick={(item) => {
              const { keyPath } = item;
              this.setState({
                defaulS: keyPath
              }) 
              this.phoneClick();
            }}
          >
            {mobileNavChildren}
          </Menu>
        }
          
        </Drawer>
      </div>
    );
  }
}

// 把store中的数据映射到组件的props
const mapStateToProps = (state) => ({
  lang: state['lang'],
})

// 把store的Diapatch映射到组件的props
const mapDispatchToProps = (dispatch) => ({
  setLangData(data) {
    const action = actionCreators.setLangData(data)
    dispatch(action)
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
