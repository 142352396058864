import React, { useEffect } from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import TweenOne from 'rc-tween-one';
import './Title.less';

const Title = ({ title, subtitle, text }) => {
  useEffect(() => {

  }, []);
  return(
    <OverPack className='titleContent mainContent' playScale={0.2}>
      <TweenOne key="0" animation={{ opacity: 1 }}
        className="code-box-shape"
        style={{ opacity: 0 }}
      >
        <div  className='title' >{title}</div>
        <div className='subBox'>
          <h1 className='subtitle'>{subtitle}</h1>
          <div className='heng'></div>
          <p className='text'>{text}</p>
        </div>
      </TweenOne>
    </OverPack>
  )
};

export default Title;