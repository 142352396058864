import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './Team.less';
import b from '@img/team/teamBanner.png';
import i1 from '@img/team/i1.png';
import i2 from '@img/team/i2.png';
import i3 from '@img/team/i3.png';
import {get, post} from '@/server/http';
import {Helmet} from "react-helmet";
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const teams = [
  { icon: i1, title: '管理团队', type: '10', title_en: 'Leadership Team' },
  { icon: i2, title: '学术团队', type: '20', title_en: 'Academic Team' },
  { icon: i3, title: '支持团队', type: '30', title_en: 'Supporting Team' },
];

const Team = ({ history, lang }) => {
  let seo_data = localStorage.getItem('seo_data');
  const [detail, setDetail] = useState({});
  useEffect(() => {
    const trackPage = page => {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    };
    trackPage(window.location.href)
    
    let _detail= {};
    const getDetail = get('/api/page/team').then(res => {
        _detail = res.data
        // console.log(_detail)
        if(seo_data){
          seo_data = JSON.parse(seo_data)
          if(!_detail.seo_title){
            _detail.seo_title = seo_data.seo_title
          }
          if(!_detail.seo_keywords){
            _detail.seo_keywords = seo_data.seo_keywords
          }
          if(!_detail.seo_description){
            _detail.seo_description = seo_data.seo_description
          }
          if(!_detail.seo_en_title){
            _detail.seo_en_title = seo_data.seo_en_title
          }
          if(!_detail.seo_en_keywords){
            _detail.seo_en_keywords = seo_data.seo_en_keywords
          }
          if(!_detail.seo_en_description){
            _detail.seo_en_description = seo_data.seo_en_description
          }
        }
        setDetail(_detail)
    })
  }, []);
  return(
    <div className='teamContent mainContBox'>
      <Helmet>
          <title>{lang?detail.seo_title:detail.seo_en_title}</title>
          <meta name="description" content={lang?detail.seo_description:detail.seo_en_description} />
          <meta name="keywords" content={lang?detail.seo_keywords:detail.seo_en_keywords} />
      </Helmet>
      <BannerItem src={lang ? detail.team_banner_image:detail.team_en_banner_image} />
      <div className='conentBox'>
        <OverPack className='childContent' playScale={0.2}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
          >
              {
            lang ? <Navtip title={['关于我们']} subtitle='学校团队' /> : <Navtip title={['About us']} subtitle='Our People' />
          }
            {
							/* <h1 className='teamTitle' key='h1'>学校团队</h1> */
						}
            <div className='teamText' key='text' style={{ lineHeight: '1.8' }}>
              <div dangerouslySetInnerHTML={{__html: lang ? detail.team_content : detail.team_en_content}}></div>
            </div>
            <div className='teamBox' key='box'>
              {
                teams.map(t => (
                  <div
                    key={lang ? t.title : t.title_en}
                    className='teamItem'
                    onClick={() => {
                      history.push(`/team/detail/${t.type}`)
                    }}
                  >
                    <img src={t.icon} alt='' className='icon' />
                    <span className='title'>{lang ? t.title : t.title_en}</span>
                  </div>
                ))
              }
            </div>
          </QueueAnim>
        </OverPack>
      </div>
    </div>
  )
};

export default connect(
    state => ({
        lang: state['lang'],
    })
)(Team);