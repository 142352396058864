import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './Eng.less';
import b from '@img/system/engBanner.png';
import eng from '@img/system/eng.png'
import {Helmet} from "react-helmet";
import {get, post} from '@/server/http';
import {pxAndRemInterturn} from '@/server/util';
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const Eng = ({ lang }) => {
  let seo_data = localStorage.getItem('seo_data');
  const [detail, setDetail] = useState({});
  useEffect(() => {
    const trackPage = page => {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    };
    trackPage(window.location.href)
    
   let _detail= {};
   const getDetail = get('/api/page/eng').then(res => {
       _detail = res.data
       var eng_content = _detail.eng_content
       var eng_en_content = _detail.eng_en_content
       var regex = new RegExp('<img', 'gi');
       _detail.eng_content = eng_content.replace(regex, '<img style="width: 100%;"');
       _detail.eng_en_content = eng_en_content.replace(regex, '<img style="width: 100%;"');
       //px转rem
       _detail.eng_content = pxAndRemInterturn(_detail.eng_content,'px','rem')
       _detail.eng_en_content = pxAndRemInterturn(_detail.eng_en_content,'px','rem')
       if(seo_data){
         seo_data = JSON.parse(seo_data)
         if(!_detail.seo_title){
           _detail.seo_title = seo_data.seo_title
         }
         if(!_detail.seo_keywords){
           _detail.seo_keywords = seo_data.seo_keywords
         }
         if(!_detail.seo_description){
           _detail.seo_description = seo_data.seo_description
         }
         if(!_detail.seo_en_title){
           _detail.seo_en_title = seo_data.seo_en_title
         }
         if(!_detail.seo_en_keywords){
           _detail.seo_en_keywords = seo_data.seo_en_keywords
         }
         if(!_detail.seo_en_description){
           _detail.seo_en_description = seo_data.seo_en_description
         }
       }
       // console.log(_detail)
       setDetail(_detail)
   })
  }, []);

  return (
    <div className='engContent mainContBox'>
      <Helmet>
          <title>{lang?detail.seo_title:detail.seo_en_title}</title>
          <meta name="description" content={lang?detail.seo_description:detail.seo_en_description} />
          <meta name="keywords" content={lang?detail.seo_keywords:detail.seo_en_keywords} />
      </Helmet>
      <BannerItem src={lang?detail.eng_banner_image:detail.eng_en_banner_image} />
      <OverPack className='childContent' playScale={0.2}>
        <QueueAnim
          type="bottom"
          key="eng"
          leaveReverse
        >
          {
            lang ? <Navtip title={['学术课堂']} subtitle='英语强化支持' /> : <Navtip title={['Academics']} subtitle='ESL Support' />
          }
					<div className='engText' key='engText1'>
					  <div dangerouslySetInnerHTML={{__html: lang?detail.eng_content:detail.eng_en_content}}></div>
					</div>
        </QueueAnim>
      </OverPack>
    </div>
  )
};

export default connect (
  state => ({
    lang: state['lang'],
  })
)(Eng);