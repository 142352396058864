import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Map from 'react-bmapgl/Map';
import Marker from 'react-bmapgl/Overlay/Marker';
import NavigationControl from 'react-bmapgl/Control/NavigationControl';
import ZoomControl from 'react-bmapgl/Control/ZoomControl';
import { Row, Col } from 'antd';
import Navtip from '@components/Navtip/Navtip';
import './Korea.less';
import address from '@img/about/address.png';
import phone from '@img/about/phone.png';
import mail from '@img/about/mail.png';
import qiw from '@img/about/qiw.png';
import wx from '@img/about/koreawx.jpg';
import face from '@img/about/face.png';
import ins from '@img/about/ins.png';
import dou from '@img/about/dou.png';
import {Helmet} from "react-helmet";
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const medias = [
    { name: '입학 상담 위챗', src: qiw, name_en: 'Admission Corporate Wechat' },
    { name: '위챗 공식 계정：klisedu', src: wx, name_en: 'Wechat Public Account: klisedu' },
    { name: 'Facebook: KLIS', src: face, name_en: 'Facebook: KLIS' },
    { name: 'Instagram: kliscq', src: ins, name_en: 'Instagram: kliscq' },
    { name: '틱톡：重庆市诺林巴蜀外籍人员子女学校', src: dou, name_en: 'Douyin: KLIS' },
];

const position = {
    lng: 106.523927, lat: 29.610182
};

const Contact = ({ lang }) => {
    let seo_data = localStorage.getItem('seo_data');
    seo_data = JSON.parse(seo_data)
    const [mediaSrc, setMediaSrc] = useState(wx);
    const [mediaIndex, setMediaIndex] = useState(1);
    useEffect(() => {
        const trackPage = page => {
          ReactGA.set({
            page,
          });
          ReactGA.pageview(page);
        };
        trackPage(window.location.href)
        console.log('联系我们');
    }, []);
    const renderContactDetails = () => (
        <Row>
            <Col md={8} xs={24} className='detailBox'>
                <div className='detailItem'>
                    <div className='iconBox'>
                        <img src={address} alt="" className='addressIcon' />
                    </div>
                    <h2 className='name'>{lang ? '학교 주소' : 'The school address'}</h2>
                    <div className='detail'>
                        <span>{lang ? '충칭시 유북구 신남로 6호' : 'Xinnan Road No.6, Yu Bei District, Chongqing, China'}</span>
                    </div>
                </div>
            </Col>
            <Col md={8} xs={24} className='detailBox'>
                <div className='detailItem'>
                    <div className='iconBox'>
                        <img src={phone} alt="" className='phoneIcon' />
                    </div>
                    <h2 className='name'>{lang ? '입학처 전화번호' : 'Contact phone number'}</h2>
                    <div className='detail' style={{ letterSpacing: '2px' }}>
                    <span>{lang?'023-67721777':'+86 23 67721777'}</span>
                    <span>{lang?'18983419081':'+86 189 8341 9081'}</span>
                    <span>{lang?'한국어 가능 17723157105':'+86 177 2315 7105'}</span>
                       
                    </div>
                </div>
            </Col>
            <Col md={8} xs={24} className='detailBox'>
                <div className='detailItem'>
                    <div className='iconBox'>
                        <img src={mail} alt="" className='mailIcon' />
                    </div>
                    <h2 className='name'>{lang ? '이메일 주소' : 'email'}</h2>
                    <div className='detail'>
                        <span>admissions@klschool.org</span>
                    </div>
                </div>
            </Col>
        </Row>
    );

    return (
        <div className='contactContent'>
            <Helmet>
                <title>{lang?seo_data.seo_title:seo_data.seo_en_title}</title>
                <meta name="description" content={lang?seo_data.seo_description:seo_data.seo_en_description} />
                <meta name="keywords" content={lang?seo_data.seo_keywords:seo_data.seo_en_keywords} />
            </Helmet>
            <div className='childContent'>
                {
                    lang ? <Navtip title={['关于我们']} subtitle='联系我们' /> : <Navtip title={['About us']} subtitle='Contact us' />
                }
                {
									//<div className='contactTitleBox'>
                 //   <h1 className='title'>{lang ? '联系我们' : 'Contact Us'}</h1>
                 //   <span className='subtitle'>{lang ? '重庆诺林巴蜀外籍人员子女学校' : 'KL International School of Chongqing Bashu'}</span>
                //</div>
								}
            </div>
            <div className='contactDeatilBox'>
                <div className='childContent'>
                    {renderContactDetails()}
                </div>
            </div>
            <div className='childContent mediaContent'>
                <h1 className='title'>{lang ? '소셜 미디어' : 'Social media'}</h1>
                <div className='mediaBox'>
                    <div className='boxLeft'>
                        {
                            medias.map((t, i) => (
                                <div
                                    key={lang ? t.name : t.name_en}
                                    className={`nameItem  ${mediaIndex === i ? 'nameActivity' : ''}`}
                                    onClick={() => {
                                        setMediaIndex(i);
                                        setMediaSrc(t.src);
                                    }}
                                >
                                    {lang ? t.name : t.name_en}
                                    <div className='xuBox'></div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='boxRight'>
                        <img src={mediaSrc} alt="" />
                    </div>
                </div>
                <div className='mapBox'>
                    <Map center={position} zoom="17" enableScrollWheelZoom={true}>
                        <Marker position={position} />
                        <NavigationControl />
                        <ZoomControl />
                    </Map>
                </div>
            </div>
        </div>
    )
};

export default connect(
    state => ({
        lang: state['lang'],
    })
)(Contact);