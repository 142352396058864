import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row, Col } from 'antd';
import './Square.less';
import {get, post} from '@/server/http';

import xu5 from '@img/home/xu5.jpg';



const mathinitData = {
  zh: [],
};

let s = 0;

const Square = ({lang}) => {
  const [mathData, setMathData] = useState(mathinitData);
  const [detail, setDetail] = useState({});
  let mathInterval = null;
  var numdata = []
  const interFun = () => {
    if(numdata.length!=0){
      var _mathData =  {
        zh: [
			         { num: numdata[5].number, unit:numdata[5].unit, title: numdata[5].title, className: '', bg: '#155ACE', span: 6,title_en: numdata[5].en_title },
          { num: 0, target: numdata[0].number, step: numdata[0].number/10, unit: numdata[0].unit, ani: true, title: numdata[0].title, className: '', bg: '#155ACE', span: 6, title_en: numdata[0].en_title },
          { num: 0, target: numdata[1].number, step: numdata[1].number/10, unit: numdata[1].unit, ani: true, title: numdata[1].title, className: '', bg: '#15CEB4', span: 6, title_en: numdata[1].en_title },
          { num: numdata[2].number, unit: numdata[2].unit, title: numdata[2].title, className: '', bg: '#15ADCE', span: 6, title_en: numdata[2].en_title},
          { num: numdata[3].number, unit: numdata[3].unit, title: numdata[3].title, className: 'changBox', bg: '#15CEB4', span: 6 , title_en: numdata[3].en_title},
          { num: 1, target: 11, step: 11, unit: numdata[4].unit, ani: true, title: numdata[4].title,  className: '', bg: '#15ADCE', span: 12, title_en: numdata[4].en_title },
 
        ]
      }
      
      mathInterval = setInterval(() => {
        const { zh } = _mathData;
        if (s === 3) {
          clearInterval(mathInterval);
          mathInterval = null;
        }
        zh.map(t => {
          if (t.ani) {
            if (t.num < t.target) {
              t.num = t.num += t.step;
            } else {
              s = s+=1;
              t.ani = false;
            }
          }
          return t;
        })
        setMathData({..._mathData});
      }, 50);
    }else{

    }
    
    
  };

  useEffect(() => {
    const getDetail = get('/api/page/index').then(res => {
        numdata = res.data.index_numdesc
        // console.log(numdata)
        interFun();
    })
    
    return () => {
      clearInterval(mathInterval);
      mathInterval = null;
      s = 0;
    }
  }, []);
  return (
    <div className="mainContent index_ssnl clear">
		<div className="index_ssbanner">
			<div className="index_ssbannershow">
				<div className="index_ssbannerimg"><img className='lineImgBox' src={xu5}/></div>
				<div className="index_ssbak">
					<div className="index_ssbakk">

						 <Row gutter={[6, 6]} >
					
						         {
						           mathData.zh.map((t, i) => (
						                 <h2><div>{t.num}{t.unit} </div>    {lang ? t.title: t.title_en}</h2>
						           ))
						         }
						 </Row>
					</div>
				</div>
			</div>
		</div>
    </div>
  )
}

export default connect(
    state => ({
        lang: state['lang'],
    })
)(Square);