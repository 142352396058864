import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './NewTeam.less';
import b from '@img/team/teamDetialBanner.png';
import b_en from '@img/team/teamDetialBanner_en.png';
import { Link } from "react-router-dom";

import Dino from '@img/team/Dino.png';
import James from '@img/team/James.png';
import Mira from '@img/team/Mira.png';
import {Helmet} from "react-helmet";
import {get, post} from '@/server/http';
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);
const teams1 = [
  { image: 'https://www.klisedu.com/1.png',name:'Aaron Thomas Ogle', en_name: 'Aaron Thomas Ogle', p: '幼儿园主班老师', p_en: 'PerK2 Homeroom Teacher' },
  { image: 'https://www.klisedu.com/2.png', name:'Anna Beskonchina',en_name: 'Anna Beskonchina', p: '幼儿园主班老师', p_en: 'PreK1A Homeroom Teacher' },
  { image: 'https://www.klisedu.com/3.png', name:'Valeria',en_name: 'Valeria', p: '幼儿园主班老师', p_en: 'PerK2 Homeroom Teacher' },
];

const teams2 = [
  { image: 'https://www.klisedu.com/4.png',name:'Daniel Herbst', en_name: 'Daniel Herbst', p: '小学主班老师', p_en: 'Primary Homeroom Teacher' },
  { image: 'https://www.klisedu.com/5.png', name:'Robert James',en_name: 'Robert James', p: '小学数学老师', p_en: 'Primary Math Teacher' },
  { image: 'https://www.klisedu.com/6.png', name:'Jared Simpson',en_name: 'Jared Simpson', p: '小学社会科学老师', p_en: 'Primary Social Studies Teacher' },
  { image: 'https://www.klisedu.com/16.png', name:'Emma Avery',en_name: 'Emma Avery', p: '小学英语语言文学老师', p_en: 'Primary ELA Teacher' },
  { image: 'https://www.klisedu.com/18.png', name:'Daniel Soedirga',en_name: 'Daniel Soedirga', p: '小学主班老师', p_en: 'Primary Homeroom Teacher' },
  { image: 'https://www.klisedu.com/19.png', name:'Rosie Olivares',en_name: 'Rosie Olivares', p: '学前主班老师', p_en: 'KG Homeroom teacher' },
];

const teams3 = [
  { image: 'https://www.klisedu.com/7.png',name:'Jennifer Freeman', en_name: 'Jennifer Freeman', p: '初中科学老师', p_en: 'MS Science Teacher' },
  { image: 'https://www.klisedu.com/8.png', name:'Waleid Hassan',en_name: 'Waleid Hassan', p: '初中数学老师', p_en: 'MS Math Teacher' },
  { image: 'https://www.klisedu.com/9.png', name:'Kathleen Lynn',en_name: 'Kathleen Lynn', p: '初中英语语言文学老师', p_en: 'MS ELA Teacher' },
];

const teams4 = [
  { image: 'https://www.klisedu.com/10.png',name:'David Turpie', en_name: 'David Turpie', p: '高中社会科学老师', p_en: 'HS Social Studies Teacher' },
  { image: 'https://www.klisedu.com/11.png', name:'Natasha Simmons',en_name: 'Natasha Simmons', p: '高中英语语言文学老师', p_en: 'HS ELA Teacher' },
  { image: 'https://www.klisedu.com/12.png', name:'Donald Xiao',en_name: 'Donald Xiao', p: '高中数学老师', p_en: 'HS Math Teacher' },
  { image: 'https://www.klisedu.com/17.png', name:'Joan Wang',en_name: 'Joan Wang', p: '高中双语科学老师', p_en: 'HS Bilingual Science Teacher' },
];

const teams5 = [
  { image: 'https://www.klisedu.com/13.png',name:'James Watt', en_name: 'James Watt', p: '体育老师', p_en: 'PE Teacher' },
  { image: 'https://www.klisedu.com/14.png', name:'Melina Xu',en_name: 'Melina Xu', p: '音乐老师', p_en: 'Music Teacher' },
  { image: 'https://www.klisedu.com/15.png', name:'Silvia Li',en_name: 'Silvia Li', p: '美术老师', p_en: 'Art Teacher' },
];

const TeamDetail = ({lang}) => {
  let seo_data = localStorage.getItem('seo_data');
  seo_data = JSON.parse(seo_data)
  const { type } = useParams();
  const [subtitle, setSubtitle] = useState('');
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const trackPage = page => {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    };
    trackPage(window.location.href)
    
    let _subtitle = '', _teams= [];
    // switch (type) {
    //   case '10':
    //     _subtitle = lang?'管理团队':'Leadership Team';
    //     break;
    //   case '20':
				// _subtitle = lang?'学术团队':'Academic Team';
    //     break;
    //   default:
				// _subtitle = lang?'支持团队':'Supporting Team';
    // }
	_subtitle = lang?'学术团队':'Academic Team';
    setSubtitle(_subtitle);
    const getList = () => {
      get('/api/team/lists',{type:type}).then((res) => {
        _teams = res.data.list
        console.log(res)
        setTeams(_teams)
      })
    }
    getList();
  }, [type]);

  return (
    <div className='teamDetailContent mainContBox'>
      <Helmet>
          <title>{lang?seo_data.seo_title:seo_data.seo_en_title}</title>
          <meta name="description" content={lang?seo_data.seo_description:seo_data.seo_en_description} />
          <meta name="keywords" content={lang?seo_data.seo_keywords:seo_data.seo_en_keywords} />
      </Helmet>
      <BannerItem src={lang?b:b_en} />
      <div className='conentBox'>
        <OverPack className='childContent' playScale={0.2}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
          >
            <div className='navContent'>
              <Link className='navA' to={'/'}>{lang ? '首页' : 'Index'}</Link> {`>`}
              <span> {lang ? '关于我们': 'About us'}</span> {`>`}
              <Link className='navA' to={'/team'}> { lang ? '学校团队' : 'The school team'}</Link> {`>`}
              <span className='tip'> {subtitle}</span>
            </div>
			<h1 className='teamTitle1' key='h1'>{ lang ? '学前教育教师团队' : 'PreSchool Teachers'}</h1>
			<div className='teamBox' key='box'>
			  {
			    teams1.map((t, i) => (
			      <div key={i} className='peopleItem'>
			        <div className='imgBox' style={{ background: `url(${t.image}) no-repeat center`, backgroundSize: 'contain' }}></div>
			        <div className='name'>{lang ? t.name: t.en_name}</div>
			        <div className='zhi'>{lang ? t.p: t.p_en}</div>
			      </div>
			    ))
			  }
			</div>
			<h1 className='teamTitle1' key='h2'>{ lang ? '小学教师团队' : 'Primary School Teachers'}</h1>
			<div className='teamBox' key='box2'>
			  {
			    teams2.map((t, i) => (
			      <div key={i} className='peopleItem'>
			        <div className='imgBox' style={{ background: `url(${t.image}) no-repeat center`, backgroundSize: 'contain' }}></div>
			        <div className='name'>{lang ? t.name: t.en_name}</div>
			        <div className='zhi'>{lang ? t.p: t.p_en}</div>
			      </div>
			    ))
			  }
			</div>
			
			<h1 className='teamTitle1' key='h3'>{ lang ? '初中教师团队' : 'Middle School Teachers'}</h1>
			<div className='teamBox' key='box3'>
			  {
			    teams3.map((t, i) => (
			      <div key={i} className='peopleItem'>
			        <div className='imgBox' style={{ background: `url(${t.image}) no-repeat center`, backgroundSize: 'contain' }}></div>
			        <div className='name'>{lang ? t.name: t.en_name}</div>
			        <div className='zhi'>{lang ? t.p: t.p_en}</div>
			      </div>
			    ))
			  }
			</div>
			
			<h1 className='teamTitle1' key='h4'>{ lang ? '高中教师团队' : 'High School Teachers'}</h1>
			<div className='teamBox' key='box4'>
			  {
			    teams4.map((t, i) => (
			      <div key={i} className='peopleItem'>
			        <div className='imgBox' style={{ background: `url(${t.image}) no-repeat center`, backgroundSize: 'contain' }}></div>
			        <div className='name'>{lang ? t.name: t.en_name}</div>
			        <div className='zhi'>{lang ? t.p: t.p_en}</div>
			      </div>
			    ))
			  }
			</div>
			
			<h1 className='teamTitle1' key='h5'>{ lang ? '体育及艺术教师团队' : 'Elective Teachers'}</h1>
			<div className='teamBox' key='box5'>
			  {
			    teams5.map((t, i) => (
			      <div key={i} className='peopleItem'>
			        <div className='imgBox' style={{ background: `url(${t.image}) no-repeat center`, backgroundSize: 'contain' }}></div>
			        <div className='name'>{lang ? t.name: t.en_name}</div>
			        <div className='zhi'>{lang ? t.p: t.p_en}</div>
			      </div>
			    ))
			  }
			</div>
          </QueueAnim>
        </OverPack>
      </div>
    </div>
  )
};

export default connect(
    state => ({
        lang: state['lang'],
    })
)(TeamDetail);