import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Link } from "react-router-dom";
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './CourseSystem.less';
import s from '@img/learn/systemBanner.png';
import gou from '@img/system/gou.png';
import {Helmet} from "react-helmet";
import {get, post} from '@/server/http';
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const stands = [
    {li: '研究和循证。', li_en: 'Research and evidence-based.'},
    {li: '清晰、理解力和一致性。', li_en: 'Clear, understandable, and consistent.'},
    {li: '符合全球大学的预期。', li_en: 'Aligned with college expectations globally.'},
    {li: '基于严谨的内容和通过高阶思维技能应用知识。', li_en: 'Based on rigorous content and application of knowledge through higher-order thinking skills.'},
    {li: '由世界上表现最好的所有国家提供信息，为所有学生在世界范围内取得成功做好准备。', li_en: 'Informed by top performing countries around the world in order to prepare all students for success in our global society.'}, 
];
var r = 87,g = 165, b = 216
const CourseSystem = ({ lang }) => {
    let seo_data = localStorage.getItem('seo_data');
    const [detail, setDetail] = useState({list:[]});
    useEffect(() => {
      const trackPage = page => {
        ReactGA.set({
          page,
        });
        ReactGA.pageview(page);
      };
      trackPage(window.location.href)
      
     let _detail= {};
     const getDetail = get('/api/course_system/lists').then(res => {
         _detail = res.data
         // console.log(_detail)
         if(seo_data){
           seo_data = JSON.parse(seo_data)
           if(!_detail.seo_title){
             _detail.seo_title = seo_data.seo_title
           }
           if(!_detail.seo_keywords){
             _detail.seo_keywords = seo_data.seo_keywords
           }
           if(!_detail.seo_description){
             _detail.seo_description = seo_data.seo_description
           }
           if(!_detail.seo_en_title){
             _detail.seo_en_title = seo_data.seo_en_title
           }
           if(!_detail.seo_en_keywords){
             _detail.seo_en_keywords = seo_data.seo_en_keywords
           }
           if(!_detail.seo_en_description){
             _detail.seo_en_description = seo_data.seo_en_description
           }
         }
         setDetail(_detail)
     })
    }, []);
    return (
        <div className='systeamContent mainContBox'>
            <Helmet>
                <title>{lang?detail.seo_title:detail.seo_en_title}</title>
                <meta name="description" content={lang?detail.seo_description:detail.seo_en_description} />
                <meta name="keywords" content={lang?detail.seo_keywords:detail.seo_en_keywords} />
            </Helmet>
            <BannerItem src={s} />
            <div className='conentBox'>
                <OverPack className='childContent' playScale={0.2}>
                    <QueueAnim
                        type="bottom"
                        key="ul"
                        leaveReverse
                    >
                        {
                            lang ? <Navtip title={['学术课程']} subtitle='课程体系' /> : <Navtip title={['Academics']} subtitle='Curriculum' />
                        }
                        <div key="top">
                            <h1 className='teamTitle' key="tit1">{lang ? '美国共同核心标准课程体系' : 'US Common Core Standards'}</h1>
                            <div className='teamText' key="t1">{lang ? '学校从 PreK到 G12 使用美国共同核心标准课程体系。 共同核心是一套高质量的学术标准和学习目标，概述了学生在每个年级结束时应掌握和能做到的内容。这些标准由来自世界各地的专家和教师制定，旨在确保所有高中毕业生都具备世界各地大学取得成功所需的技能和知识。' : 'KLIS uses the United States Common Core Standards from PreK through G12. The Common Core is a set of high-quality academic standards and learning goals that outline what a student should know and be able to do at the end of each grade. The standards were created by experts and teachers from across the globe and are designed to ensure all students graduate from high school with the skills and knowledge necessary to succeed in university anywhere around the world. '}</div>
                            <div className='teamText' key="t2">{lang ? '在学校课程框架内，我们的标准评估每个目标内的多种技能，从而在批判性思维和问题解决的技能方面取得更大的发展。我们的标准使教师能够更详细地跟踪学生全年个性化发展的提升，从而制定可以立即解决任何潜在缺陷的定制计划。' : 'Within the KLIS Curriculum Framework, our standards evaluate multiple skills within each objective, allowing for a greater development in critical thinking and problem-solving. Our standards make it possible for the teachers to track individualized student progress throughout the year with greater detail, allowing for customized plans to be developed that can address any potential deficiencies right away.'}</div>
                            <div className='teamText' key="t3">{lang ? '在过去，学生想出正确的答案就是合格的。而一个合格的学生可以从不同信息来源来查找答案，写下来并得到他们的分数。 根据学生历史最佳学习记录，学校课程要求学生做的不仅仅是找到正确的答案。 他们还必须能够展示他们是如何得出具体答案的，然后能够适当地做出解释。' : 'In the past, it was simply good enough for a student to come up with the correct answer. A smart enough student could look up the answer from a different source, write it down, and get their grade. With information access at its highest levels in history, the KLIS curriculum forces students to do more than find the correct answer. They must also be able to show how they arrived at their specific answer and then be able to appropriately defend it.'}</div>
                        </div>
                        <QueueAnim className='standardBox' key="middle" type="right">
                            <h1 className='teamTitle' key='tit2'>{lang ? '学校学术标准：' : 'The KLIS Academic Standards are:'}</h1>
                            {
                                stands.map((t,i) => (
                                    <div key={lang ? t.li:t.li_en} className='sItem'>
                                        <span className='sText'>
                                            <img src={gou} className='gou' alt="" />
                                            <span>{lang ? t.li:t.li_en}</span>
                                        </span>
                                    </div>
                                ))
                            }
                        </QueueAnim>
                        <QueueAnim
                            type="bottom"
                            key="bottom"
                            leaveReverse
                            className='teamBox'
                        >
                            {
                                
                              detail.list.map((t,i) => (
                              <>
                                    <Link key={lang ? t.title : t.en_title} className='sysItem' style={{ background: 'rgb('+(r - i * 20)+','+(g - i * 20)+','+(b - i * 20)+')' }} to={`/system/detail/${t.id}`}>
                                        <span className='title'>{lang ? t.title : t.en_title}</span>
                                    </Link>
                              </>
                                ))
                            }
                        </QueueAnim>
                    </QueueAnim>
                </OverPack>
            </div>
        </div>
    )
};

export default connect(
    state => ({
        lang: state['lang'],
    })
)(CourseSystem);