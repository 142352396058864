import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import BannerItem from '@components/BannerItem/BannerItem';
import Navtip from '@components/Navtip/Navtip';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { chineseText } from './applyText';
import './Apply.less';
import b from '@img/applyBanner.png';
import llt from '@img/llt.jpg';
import lltEn from '@img/llten.jpeg';
import liucheng from '@img/liucheng.png';
import liucheng2 from '@img/liucheng2.png';
import {get, post} from '@/server/http';
import {pxAndRemInterturn} from '@/server/util';
import {Helmet} from "react-helmet";
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const Apply = ({ lang }) => {
  let seo_data = localStorage.getItem('seo_data');
  const [detail, setDetail] = useState({});
  useEffect(() => {
    const trackPage = page => {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    };
    trackPage(window.location.href)
    
   let _detail= {};
   const getDetail = get('/api/page/apply').then(res => {
       _detail = res.data
       var apply_content = _detail.apply_content
       var apply_en_content = _detail.apply_en_content
       var regex = new RegExp('<img', 'gi');
       _detail.apply_content = apply_content.replace(regex, '<img style="width: 100%;"');
       _detail.apply_en_content = apply_en_content.replace(regex, '<img style="width: 100%;"');
       //px转rem
       _detail.apply_content = pxAndRemInterturn(_detail.apply_content,'px','rem')
       _detail.apply_en_content = pxAndRemInterturn(_detail.apply_en_content,'px','rem')
       // console.log(_detail)
       if(seo_data){
         seo_data = JSON.parse(seo_data)
         if(!_detail.seo_title){
           _detail.seo_title = seo_data.seo_title
         }
         if(!_detail.seo_keywords){
           _detail.seo_keywords = seo_data.seo_keywords
         }
         if(!_detail.seo_description){
           _detail.seo_description = seo_data.seo_description
         }
         if(!_detail.seo_en_title){
           _detail.seo_en_title = seo_data.seo_en_title
         }
         if(!_detail.seo_en_keywords){
           _detail.seo_en_keywords = seo_data.seo_en_keywords
         }
         if(!_detail.seo_en_description){
           _detail.seo_en_description = seo_data.seo_en_description
         }
       }
       setDetail(_detail)
   })
  }, []);
    return (
        <div className='applyContent'>
          <Helmet>
              <title>{lang?detail.seo_title:detail.seo_en_title}</title>
              <meta name="description" content={lang?detail.seo_description:detail.seo_en_description} />
              <meta name="keywords" content={lang?detail.seo_keywords:detail.seo_en_keywords} />
          </Helmet>
            <BannerItem src={lang?detail.apply_banner_image:detail.apply_en_banner_image} />
            <div className='childContent'>
						<OverPack className='mainContent' playScale={0.2}>
						  <QueueAnim
						    type="bottom"
						    key="ul"
						    leaveReverse
						  >
                {
                    lang ? <Navtip subtitle='申请入学' /> : <Navtip subtitle='Admissions ' />
                }

                <div className='applyText'>
                   <div dangerouslySetInnerHTML={{__html: lang?detail.apply_content:detail.apply_en_content}}></div>
                </div>

                <div className='applyBtnbox'>
								{
									//<Link to='/apply' className='btn'>{lang ? '立即申请' : 'Apply Now'}</Link>
									<a className='btn' target='_blank' href='https://www.wenjuan.com/s/qUfmAjO/'>{lang ? '立即申请' : 'Apply Now'}</a>
								}
                    
                </div>
								</QueueAnim>
							</OverPack>
            </div>
        </div>
    )
};

export default connect(
    state => ({
        lang: state['lang'],
    })
)(Apply);