import React, { useEffect, useState } from 'react';
import { Carousel } from 'antd';
import { connect } from 'react-redux';
import Article from './Article/Article';
import './index.less';
import Title from '@components/Title/Title';
import BannerItem from '@components/BannerItem/BannerItem';
import Square from './Square/Square';
import News from './News/News';
import BgShow from './BgShow/BgShow';
import Campus from './Campus/Campus';
import banner1 from '@img/home/ba1.png';
import banner2 from '@img/home/ba2.png';
import banner3 from '@img/home/ba3.png';
import {Helmet} from "react-helmet";
import {get, post} from '@/server/http';
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);
const bannerData = [banner1, banner2, banner3];
const Index = (props) => {
    let seo_data = localStorage.getItem('seo_data');
    seo_data = JSON.parse(seo_data)
    const trackPage = page => {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    };
    trackPage(window.location.href)
    const { lang } = props;
    const [banners, setBanners] = useState([]);
    const [detail, setDetail] = useState({});
    useEffect(() => {
      let _banners= [];
      const getList = get('/api/banner/lists').then(res => {
          _banners = res.data.list
          console.log(_banners)
          setBanners(_banners)
      })
      let _detail= {};
      const getDetail = get('/api/page/index').then(res => {
          _detail = res.data
          // console.log(_detail)
          setDetail(_detail)
      })
    },[]);
    return (
        <div>
            <Helmet>
                <title>{lang?seo_data.seo_title:seo_data.seo_en_title}</title>
                <meta name="description" content={lang?seo_data.seo_description:seo_data.seo_en_description} />
                <meta name="keywords" content={lang?seo_data.seo_keywords:seo_data.seo_en_keywords} />
            </Helmet>
            <Carousel autoplay>
                {/* <div className='videoBox'>
          <video
            preload="metadata"
            width='100%'
            autoPlay
            loop
            muted
          >
            <source src="https://test.szyueanshun.com/uploads/freelunch.mp4" type="video/mp4" />
          </video>
        </div> */}
                {
                  lang ? (
                    banners.map((t,i) => (
                        <BannerItem
                            key={i}
                            src={t.image?t.image.file_path:''}
                        />
                    ))
                  ):(
                    banners.map((t,i) => (
                        <BannerItem
                            key={i}
                            src={t.en_image?t.en_image.file_path:''}
                        />
                    ))
                  )
                    
                }
            </Carousel>
            
             <Title
                title="KLIS IN NUMBERS"
                subtitle={ lang ? detail.index_title : detail.index_en_title }
                text={ lang ? detail.index_sub_title : detail.index_en_sub_title }
            />
			
            <Square />
            <Campus />
            <BgShow
                text={ lang ? detail.index_intro : detail.index_en_intro }
            />
            <Article detail={detail}/>
            <BgShow
                text={lang ? detail.index_bootom: detail.index_en_bootom}
                name={lang ? '—— 在读学生家长' : '—— Parent of KLIS Students'}
            />
            <Title
                title="SCHOOL NEWS"
                subtitle={ lang ? '学校新闻' : 'School News' }
                text={lang ? '从世界级的课堂，到学生和学校成就，到戏剧和可持续发展等社区关系活动……重庆市诺林巴蜀外籍人员子女学校的每一天都精彩纷呈。' : "From world-class practitioners to student and school achievements, along with network wide events in everything from drama to sustainability, a day at KLIS is never dull."}
            />
            <News />
			
			<div className='applyBtnbox'>
							{
								//<Link to='/apply' className='btn'>{lang ? '立即申请' : 'Apply Now'}</Link>
								<a className='btn'  target='_blank' href='https://www.wenjuan.com/s/qUfmAjO/'>{lang ? '立即申请' : 'Apply Now'}</a>
							}
			    
			</div>
        </div>
    )
};

export default connect(
    state => ({
        lang: state['lang'],
    })
)(Index);