import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import BannerItem from '@components/BannerItem/BannerItem';
import './Resource.less';
import b from '@img/resource/resourceBanner.png';
import {Helmet} from "react-helmet";
import {get, post} from '@/server/http'
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const Resource = ({lang}) => {
  let seo_data = localStorage.getItem('seo_data');
  seo_data = JSON.parse(seo_data)
  const [data,setData] = useState({list:[],page:1,total:0,current:1})
  const [page,setQuery] = useState(1)
  
  useEffect(() => {
    const trackPage = page => {
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page);
    };
    trackPage(window.location.href)
    
    const getList = () => {
      get('/api/resource/lists',{page:page}).then((res) => {
        console.log(res)
        setData({list:res.data.list,total:res.data.last_page,current:res.data.current_page})
      })
    }
    getList()
  }, [page]);
  
  const renderPage = (data) => {
    const { total, current } = data;
    let html = [];
    if (total > 0) {
      
      if(total > 1){
        html.push(<div key='0' className={`item ${1 === current ? 'disabled' : 'enabled'}`} onClick={1 !== current?() => setQuery(current - 1):() => {}}>«</div>);
        if(current>1){
          html.push(<div key={current-1} className='item' onClick={() => setQuery(current-1)}>{current-1}</div>);
        }
        html.push(<div key={current} className='item current' onClick={() => setQuery(current)}>{current}</div>);
        if(total > current + 1){
          html.push(<div key={current+1} className='item' onClick={() => setQuery(current+1)}>{current+1}</div>);
        }
        html.push(<div key={total + 1} className={`item ${total === current ? 'disabled' : 'enabled'}`} onClick={total !== current ? () => setQuery(current + 1) : () => {}}>»</div>);
      }else{
        for (let i = 1; i <= total; i++) {
          html.push(<div key={i} className={`item ${i === current ? 'current' : ''}`} onClick={() => setQuery(i)}>{i}</div>);
        }
      }
      
      
      
    }
    return html;
  };

  return (
    <div className='resourceContent mainContBox'>
      <Helmet>
          <title>{lang?seo_data.seo_title:seo_data.seo_en_title}</title>
          <meta name="description" content={lang?seo_data.seo_description:seo_data.seo_en_description} />
          <meta name="keywords" content={lang?seo_data.seo_keywords:seo_data.seo_en_keywords} />
      </Helmet>
      <BannerItem src={b} />
      <div className='conentBox'>
        <OverPack className='mainContent' playScale={0.2}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
          >
              {
                        lang ? <Navtip title={['最新动态']} subtitle='资源下载' />: <Navtip title={['School Updates']} subtitle='Resources ' />
                    }
										{
											//<h1 className='pageHTitle' key='h1'>{lang ? '资源下载': 'Download resources'}</h1>
										}
            <QueueAnim className='resourceBox' key='box' type="bottom" component={Row} leaveReverse>
              {
                data.list.map((t, i) => (
                  <Col md={8} xs={12} key={i} className='resourceItem'>
                    <a className='link' target='_blank' href={t.file? t.file.file_path: ''}  download  key={i}>
                      <div className='itemBox'>
                        <div className='imgBox' title={ lang ? t.title : t.en_title } style={{ background: `url(${t.image? t.image.file_path:''}) no-repeat center #D7D7D7`, backgroundSize: 'cover' }}>
                        </div>
                        <div className='name text-over' title={ lang ? t.title : t.en_title }>{ lang ? t.title : t.en_title }</div>
                      </div>
                    </a>
                  </Col>
                ))
              }
            </QueueAnim>
          </QueueAnim>
          <div className='pageBox'>
            {
              renderPage(data).map(t => t)
            }
          </div>
        </OverPack>
      </div>
    </div>
  )
};

export default connect(
    state => ({
        lang: state['lang'],
    })
)(Resource);