import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Navtip from '@components/Navtip/Navtip';
import { Link } from "react-router-dom";
import BannerItem from '@components/BannerItem/BannerItem';
import './SysDetail.less';
import youBanner from '@img/learn/youBanner.png';
import youMain from '@img/learn/youMain.png';
import xiaoBanner from '@img/learn/xiaoBanner.png';
import xiao1 from '@img/learn/xiao1.png';
import chuBanner from '@img/learn/chuBanner.png';
import chu1 from '@img/learn/chu1.png';
import gaoBanner from '@img/learn/gaoBanner.png';
import gao1 from '@img/learn/gao1.png';
import {get, post} from '@/server/http';
import {pxAndRemInterturn} from '@/server/util';
import {Helmet} from "react-helmet";
import {GTM_ID} from '@/server/apiIp';
import ReactGA from "react-ga";
ReactGA.initialize(GTM_ID);

const SysDetail = ({lang}) => {
    let seo_data = localStorage.getItem('seo_data');
    const type = 1
    const { id } = useParams();
    const [detail, setDetail] = useState({});
    useEffect(() => {
      const trackPage = page => {
        ReactGA.set({
          page,
        });
        ReactGA.pageview(page);
      };
      trackPage(window.location.href)
      
        let _detail= {};
        const getDetail = get('/api/course_system/detail',{id:id}).then(res => {
            _detail = res.data.detail
            var content = _detail.content
            var en_content = _detail.en_content
            var regex = new RegExp('<img', 'gi');
            _detail.content = content.replace(regex, '<img style="width: 100%;"');
            _detail.en_content = en_content.replace(regex, '<img style="width: 100%;"');
            //px转rem
            _detail.content = pxAndRemInterturn(_detail.content,'px','rem')
            _detail.en_content = pxAndRemInterturn(_detail.en_content,'px','rem')
            if(seo_data){
              seo_data = JSON.parse(seo_data)
              if(!_detail.seo_title){
                _detail.seo_title = seo_data.seo_title
              }
              if(!_detail.seo_keywords){
                _detail.seo_keywords = seo_data.seo_keywords
              }
              if(!_detail.seo_description){
                _detail.seo_description = seo_data.seo_description
              }
              if(!_detail.seo_en_title){
                _detail.seo_en_title = seo_data.seo_en_title
              }
              if(!_detail.seo_en_keywords){
                _detail.seo_en_keywords = seo_data.seo_en_keywords
              }
              if(!_detail.seo_en_description){
                _detail.seo_en_description = seo_data.seo_en_description
              }
            }
            // console.log(_detail)
            setDetail(_detail)
        })
    }, []);

    return (
        <div className='sysDetailContent mainContBox'>
            <Helmet>
                <title>{lang?detail.seo_title:detail.seo_en_title}</title>
                <meta name="description" content={lang?detail.seo_description:detail.seo_en_description} />
                <meta name="keywords" content={lang?detail.seo_keywords:detail.seo_en_keywords} />
            </Helmet>
            <BannerItem src={detail.banner?detail.banner.file_path:''} />
            <div className='conentBox'>
                <OverPack className='childContent' playScale={0.1}>
                    <QueueAnim
                        type="bottom"
                        key="ul"
                        leaveReverse
                    >
                        <div className='navContent'>
                            <Link className='navA' to={'/'}>{lang ? '首页' : 'Index'}</Link> {`>`}
                            <span> {lang ? '学术课程' : 'Academic course'}</span> {`>`}
                            <Link className='navA' to={'/system'}> {lang ? '课程体系' : 'Curriculum system'}</Link> {`>`}
                            <span className='tip'> {lang ? detail.title : detail.en_title}</span>
                        </div>
                        <div className='textBox'>
                            <div dangerouslySetInnerHTML={{__html: lang?detail.content:detail.en_content}}></div>
                        </div>
                    </QueueAnim>
                </OverPack>
            </div>
        </div>
    )
};

export default connect(
    state => ({
        lang: state['lang'],
    })
)(SysDetail);