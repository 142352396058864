import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Link } from "react-router-dom";
import './Footer.less'
import i1 from '@img/footer/i1.png'
import i2 from '@img/footer/i2.png'
import i3 from '@img/footer/i3.png'
import i4 from '@img/footer/i4.png'
import i5 from '@img/footer/i5.png'
import k from '@img/home/k.png'

import wx from '@img/about/wx.png';
import face from '@img/about/face.png';
import ins from '@img/about/ins.png';
import dou from '@img/about/dou.png';

import btn1 from '@img/bt-btn1.png';
import btn2 from '@img/bt-btn2.png';
import btn3 from '@img/bt-btn3.png';
import btn4 from '@img/bt-btn4.png';
import {get, post} from '@/server/http';
const btnList = [
    { name: '申请入读', name_en: 'Apply', type: 'a', href: 'https://www.wenjuan.com/s/qUfmAjO/', bg: '#308CE7', icon: btn1 },
    { name: '职业发展', name_en: 'Careers', type: 'link', href: '/join', bg: '#E73030', icon: btn2 },
    { name: '校历', name_en: 'Calendar', type: 'download', href: '/xiaoli.pdf', bg: '#30E7AA', icon: btn3 },
    { name: '联系我们', name_en: 'Contact', type: 'link', href: '/contact', bg: '#E67130', icon: btn4 },
];
const medias = [
    // { icon: i1, bg: '#19C4FB', w: 17.5, h: 11.5 },
    { icon: i2, bg: '#2DC400', w: 34, h: 28, qrcode: wx ,type: 'wx'},
    { icon: i3, bg: '#4267B2', w: 20, h: 37, qrcode: face ,type: 'face'},
    { icon: i4, bg: 'yellow', w: 42, h: 42, qrcode: ins ,type: 'ins'},
    { icon: i5, bg: '#110A17', w: 26, h: 30, qrcode: dou ,type: 'dou'},
];
const Footer = ({ lang }) => {
    const [btns, setBtns] = useState([]);
    var arrBtn = btnList
    useEffect(() => {
      let _btns = arrBtn
      const getDetail = get('/api/page/calendar').then(res => {
          var data = res.data
          // console.log(data)
          // console.log(_btns)
          _btns[2].href = lang?data.calendar_file:data.calendar_en_file
          setBtns(_btns)
      })
    }, []);
    return (
        <OverPack className='footerContent' playScale={0.2}>
            <QueueAnim
                className='footerContainer'
                type="bottom"
                key="ul"
                leaveReverse
            >
                <div
                    className='btnBox'
                    key='btnBox'
                >
                    {
                        btns.map(t => (
                            t.type === 'download' ? <a href={t.href} className='btn hoverTop' download style={{ background: t.bg }} key={lang ? t.name : t.name_en}>
                                <span className='iconBox'><img src={t.icon} /></span>
                                <span>{lang ? t.name : t.name_en}</span>
                            </a> : t.type === 'a' ? <a href={t.href} target='_blank' className='btn hoverTop' style={{ background: t.bg }} key={lang ? t.name : t.name_en}><span className='iconBox'><img src={t.icon} /></span>
                                <span>{lang ? t.name : t.name_en}</span></a> : <Link to={t.href} className='btn hoverTop' style={{ background: t.bg }} key={lang ? t.name : t.name_en}>
                                <span className='iconBox'><img src={t.icon} /></span>
                                <span>{lang ? t.name : t.name_en}</span>
                            </Link>))
                    }
                </div>
                <div className='footerBottom' key='footerBottom'>
                    <div className='addressBox'>
                        <p>{lang ? '招生热线：023-67721777  /  18983419081  /  17723157105' : 'Telephone: +86 23 67721777 / +86 18983419081 / +86 17723157105'}</p>
                        <p>{lang ? '学校地址：重庆市渝北区新南路6号' : 'Address: Xinnan Road No.6, Yu Bei District, Chongqing, China'}</p>
                        <p><Link to='privacypolicy'>{lang ? '隐私政策' : 'Privacy policy'}</Link></p>
                    </div>
                    <div>
                        <img src={k} alt="" className='kimg hoverTop' />
                    </div>
                    <div className='mediaBox'>
                        <div className='tip'>{lang ? '一键关注学校社交媒体' : 'Follow Us On Social Media'}</div>
                        <div className='mediaIconBox'>
                            {
                                medias.map((t, i) => (
																		t.type=='face'?(
																			<span className='iconItem ' style={{ background: t.bg }} key={i}>
																					<a href='https://www.facebook.com/KLISCQ' target='_blank'>
																						<img src={t.icon} alt="" />
																					</a>
																			</span>
																		) :
																	 (
																		 <span className='iconItem hoverTop' style={{ background: t.bg }} key={i}>
																				 <img src={t.icon} alt="" />
																				 <span className='qrcodeBox'>
																						 <div className='qrcodeItem qrcodeItem1'>
																								 <img src={t.qrcode} alt="" />
																						 </div>
																				 </span>
																		 </span>
																	 )
                                    

                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='coryBox' key='coryBox'>
                    <p>@ 2022 {lang ? '重庆诺林巴蜀外籍人员子女学校官网' : 'KL International School of Chongqing Bashu'}</p>
                    <p className='margin-top-s'>渝ICP备2022001748号-1</p>
                </div>
            </QueueAnim>
        </OverPack>
    )
}

export default connect(
    state => ({
        lang: state['lang'],
    })
)(Footer);